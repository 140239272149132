import ISurvey from "core/interfaces/ISurvey";

export const compliesWithRule: (condition: string, got: any, expected: any) => (boolean) =
    (condition, got, expected) => {

        if (!isDefined(got) || !isDefined(expected)) return false

        switch (condition) {
            case 'lt':
                return got < expected;
            case 'gt':
                return got > expected;
            case 'lte':
                return got <= expected;
            case 'gte':
                return got <= expected;
            case 'eq':
            case 'id':
                return got === expected;
            case 'neq':
            case 'nid':
                return got !== expected;
        }

        return false
    }

const isDefined: (value: any) => (boolean) = (value) => value !== null && value !== undefined

export const cleanId: (id: string) => string = (id) => id.split('.')[id.split('.').length - 1];

export const checkSurveyProgress = (answers: { [key: string]: any }): number => {
    const total = Object.keys(answers).length;
    let answeredCount = 0;
    for (const value of Object.values(answers)) {
        if (value.isEmpty === false) answeredCount++;
    }

    return Math.round((answeredCount * 100) / total);
}

export const checkNeedsConfigPage = (survey: ISurvey): boolean => {
    if (Object.keys(survey.presurvey).length > 0) return true;

    return false;
};

export const isDateInRange = (date: Date, dateFrom: Date, dateTo: Date): boolean => {
    return date >= dateFrom && date <= dateTo;
}