import RenderNested from "core/classes/RenderNested";
import DateInputComponent from "../components/DateInput";

export default class DateInput extends RenderNested<null> {

  getComponent() {
    return DateInputComponent;
  }
  
}
