import React from 'react';
import Layout from 'containers/Layout';
import Header from 'components/Header';
import styled from 'styled-components';
import { BG } from '@etiquette-ui/colors';
import { H1, P } from '@etiquette-ui/typography';
import useI18n from 'i18n/useI18n';
import Icon from '@etiquette-ui/icons';
import useSurvey from 'state/survey';
import { Card } from '@etiquette-ui/misc';
import PrimaryButton from '@etiquette-ui/buttons';
import usePreSurvey from 'state/presurvey';
import { DOTELL_URL } from 'resources/constants/urls';

import useWindowSize from 'hooks/useWindowSize';
import useSettings from 'state/settings';

export const Container = styled.div<{ backgroundColor?: string }>`
    width: 100%;
    height: 88%;
    flex: 1;
    display: flex;
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : BG)};
    border-radius: 24px;
    padding: 20px 30px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
`;

export const CustomH1 = styled(H1)`
    font-size: 36px;
    text-align: center;
`;

export const CustomP = styled(P)`
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    font-size: 26px;
    text-align: center;
    line-height: normal;
`;

const CustomCard = styled(Card)`
    padding: 15px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Button = styled(PrimaryButton)`
    width: 75%;
    height: unset;
    display: flex;
    button {
        height: unset;
        margin: auto;
        padding: 15px 0;
        max-width: 400px;
        font-size: 16px;
    }
`;

const Complete: React.FC = () => {
    const {
        state: { survey },
    } = useSurvey();
    const { translateObject } = useI18n();
    const { state: settinsState } = useSettings();
    const [, windowHeight] = useWindowSize();

    const onClick = () => {
        const otherSurvey = settinsState.reference;
        const link = !!otherSurvey ? `${DOTELL_URL}${otherSurvey}` : `/${survey?._id}`;
        window.location.replace(link);
    };

    const onLinkSectionClick = () => {
        if(survey === null || survey.endScreen.linkSection === null) return;
        window.location.replace(survey.endScreen.linkSection.url);
    };

    return (
        <Layout height={windowHeight}>
            <Header logoURL={survey?.style.logo ?? ''} />
            <Container backgroundColor={survey?.style.background}>
                <div onClick={onClick}>
                    <Icon
                        icon={'ok'}
                        styles={{
                            svg: {
                                width: '90px',
                                height: '90px',
                                marginRight: '10px',
                            },
                        }}
                    />
                </div>
                {!!survey?.endScreen.title ? (<CustomH1>{translateObject(survey.endScreen.title)}</CustomH1>) : undefined}
                {!!survey?.endScreen.subtitle ? (<CustomP>{translateObject(survey.endScreen.subtitle)}</CustomP>) : undefined}
                {survey !== null && survey.endScreen.linkSection !== null && (
                    <>
                        <CustomCard style={{ marginTop: '100px', maxWidth: '1124px' }}>
                            {survey.endScreen.linkSection.text !== null && <CustomP style={{ marginBottom: '10px' }} dangerouslySetInnerHTML={{__html: translateObject(survey.endScreen.linkSection.text)}} />}
                            <Button
                                onClick={onLinkSectionClick}
                                type="button"
                            >
                                {translateObject(survey.endScreen.linkSection.buttonText)}
                            </Button>
                        </CustomCard>
                    </>
                )}
            </Container>
        </Layout>
    );
};

export default Complete;
