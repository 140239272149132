import { FC } from "react";
import { Input } from "@etiquette-ui/inputs-dropdowns";
import { FullColumn, HalfColum, Row } from "./styles";
import useI18n from "i18n/useI18n";
import {
    tableLabel,
    amountLabel,
    guestLabel,
} from "./translations";
import AnswerType from "./types/AnswerType";
import useUpdateAnswer from "./data/useUpdateAnswer";
import ServerDropdown from "./ServerDropdown";

const Ticket: FC<{
    id: string;
    section: AnswerType | null;
}> = ({ id, section }) => {
    const { translateObject } = useI18n();
    const { updateTicket } = useUpdateAnswer(id, section);
    const ticketN = section?.ticket?.ticketN;

    return (
        <>
            <Row>
                <HalfColum>
                    <Input
                        iconProps={{
                            icon: "number",
                        }}
                        labelProps={{
                            label: "Ticket",
                        }}
                        inputProps={{
                            onChange: (e: React.FormEvent<HTMLInputElement>) =>
                                updateTicket("ticketN", isNaN(+e.currentTarget.value) || +e.currentTarget.value === 0 ? null : +e.currentTarget.value ),
                            value: !ticketN || ticketN === null ? "" : ticketN.toString(),
                            type: "text",
                        }}
                    />
                </HalfColum>
                <HalfColum>
                    <Input
                        iconProps={{
                            icon: "number",
                        }}
                        labelProps={{
                            label: translateObject(tableLabel),
                        }}
                        inputProps={{
                            onChange: (e: React.FormEvent<HTMLInputElement>) =>
                                updateTicket("table", +e.currentTarget.value),
                            value: section?.ticket?.table ?? "",
                            type: "number",
                            disabled: true,
                        }}
                    />
                </HalfColum>
            </Row>
            <Row>
                <FullColumn>
                    <ServerDropdown id={id} section={section}/>
                </FullColumn>
            </Row>
            <Row>
                <HalfColum>
                    <Input
                        iconProps={{
                            icon: "price",
                        }}
                        labelProps={{
                            label: translateObject(amountLabel),
                        }}
                        inputProps={{
                            onChange: (e: React.FormEvent<HTMLInputElement>) =>
                                updateTicket("amount", +e.currentTarget.value),
                            value: section?.ticket?.amount ?? "",
                            type: "number",
                            disabled: true,
                        }}
                    />
                </HalfColum>
                <HalfColum>
                    <Input
                        iconProps={{
                            icon: "user",
                        }}
                        labelProps={{
                            label: translateObject(guestLabel),
                        }}
                        inputProps={{
                            onChange: (e: React.FormEvent<HTMLInputElement>) =>
                                updateTicket("guests", +e.currentTarget.value),
                            value: section?.ticket?.guests ?? "",
                            type: "number",
                        }}
                    />
                    {/* {!!guestsFromTicket && <label style={{ color: TEXT_PLACEHOLDER , fontSize: '0.875rem' }}>{`${translateObject(waiterGuestsLabel)}${guestsFromTicket})`}</label>} */}
                </HalfColum>
            </Row>
        </>
    );
};

export default Ticket;
