import { Dispatch } from "react";
import { SurveyActions } from "./actionTypes";
import { fetchStartAction, fetchMetadataStartAction, resetError } from "./actions";

export default class ProductsDispatcher {
    _dispatch: Dispatch<SurveyActions>;

    constructor(dispatch: Dispatch<SurveyActions>) {
        this._dispatch = dispatch;
    }

    fetchMetadataStart = (uuid: string) => this._dispatch(fetchMetadataStartAction(uuid));

    fetchStart = () => this._dispatch(fetchStartAction());

    resetError = () => this._dispatch(resetError());
}