import PreSurveyComponent, { PreSurveyStatus } from 'core/presurvey/interfaces/PreSurveyComponent';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Dropdown } from "@etiquette-ui/inputs-dropdowns";
import useI18n from 'i18n/useI18n';
import { getUsersOfGroup } from 'resources/api';
import Translation from 'core/render/interfaces/Translation';
import IUser from 'core/interfaces/IUser';
import useSurvey from 'state/survey';
import useSettings from 'state/settings';
import usePreSurvey from 'state/presurvey';
import EmployeeSelectAnswer from '../answers/EmployeeSelect';
import PreSurveyType from '../interfaces/PreSurveyType';

const Container = styled.div`
    width: 100%;
    max-width: 500px;

    @media( min-width: 768px ){
        width: 70%;
    }
`;

type SettingsType = {
    name: Translation,
    role?: number,
    division?: number,
}

type Option = {
    label: string;
    value: number;
}

const createAnswer: (id: number, name: string)=>EmployeeSelectAnswer = (id, name) => ({
    type: 'employee-select', id, name
});

const EmployeeSelect: React.FC<PreSurveyComponent<SettingsType>> = ({ id: _id, settings }) => {
    const { translateObject, translate } = useI18n();
    const { state: { survey } } = useSurvey();
    const [options, setOptions] = useState<Option[]>([]);
    const { dispatcher: settingsDispatcher } = useSettings();
    const { state: { sections }, dispatcher: preSurveyDispatcher } = usePreSurvey();

    useEffect(()=>{
        loadEmployees();
        settingsDispatcher.setConfigSectionsStatus(_id, PreSurveyStatus.ERROR);
    }, [settingsDispatcher, _id]);

    const loadEmployees = async () => {
        if (!survey) return;
        const res = (await getUsersOfGroup({groupId: survey.meta.groupId, division: settings.division, role: settings.role})) as IUser[];
        if(res.length === 0) return;
        const options = res.filter((user) => user.isCurrentlyCheckedIn).map((user)=>({label: user.name, value: user.id}));
        setOptions(options);
        onChange(options[0].value, options[0].label);
    }

    const onChange = (id: number, name: string) => {
        preSurveyDispatcher.setPreSurvey(_id, createAnswer(id, name));
    };

    useEffect(()=>{
        if(sections[_id] !== null) settingsDispatcher.setConfigSectionsStatus(_id, PreSurveyStatus.VALID);
    }, [sections, _id, settingsDispatcher]);

    const section = useMemo(() => sections[_id] as PreSurveyType, [sections, _id]);

    return (
        <Container>
                    <Dropdown
                        labelProps={{
                            label: translateObject(settings.name),
                        }}
                        selectProps={{
                            onChange: (option: Option) => onChange(option.value, option.label),
                            options: options,
                            placeholder: translate("Select an option"),
                            value: section && section.type === 'employee-select'
                                ? {
                                      label: section.name,
                                      value: section.id,
                                  }
                                : null,
                        }}
                    />
        </Container>
    );
};

export default EmployeeSelect;