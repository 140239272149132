import styled from 'styled-components';
import { BG } from '@etiquette-ui/colors';

const SurveyBackground = styled.div<{ backgroundColor?: string }>`
    display: flex;
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : BG)};
    width: 100%;
    padding-bottom: 15px;
    border-radius: 0px 0px 24px 24px;
`;

export default SurveyBackground;
