import React from 'react';
import styled from 'styled-components';
import PrimaryButton from '@etiquette-ui/buttons';

const ButtonContainer = styled.div`
    position: absolute;
    left: 0px;
    bottom: 0;
    right: 0px;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    background-color: white;
    width: 100%;
    box-shadow: 0px -4px 12px #03326914;

    @media( min-width:1124px  ){
        background-color: transparent;
        box-shadow: none;
    }
`;

const Button = styled(PrimaryButton)`
    width: 75%;
    height: unset;
    display: flex;
    button {
        height: unset;
        margin: auto;
        padding: 15px 0;
        max-width: 400px;
        font-size: 16px;
    }
`;

const SubmitButton: React.FC<{ children: any }> = ({ children }) => {
    return (
        <ButtonContainer>
            <Button>{children}</Button>
        </ButtonContainer>
    );
};

export default SubmitButton;
