import { createElement } from "react";
import Renderer from "./Renderer";
import Nesteable from "../interfaces/Nesteable";
import NestedComponent from "core/classes/NestedComponent";

export default abstract class RenderNested<T> extends Renderer implements Nesteable {

  abstract getComponent(): any;

  render() {
    const element = this._factory.createQuestion(this._question, this._id);

    const props = {
      key: this._id,
      id: this._id,
      question: this._question,
      dataAccess : this.getDataAccessor.bind(this),
      nested: this.renderChildrens(),
    };

    return createElement(element.getComponent(), props);
  }

  renderChildrens() {
    let result: NestedComponent<T>[] = [];

    if (this._question.nested) {
      const subQuestions = Object.entries(this._question.nested).sort((a,b)=>a[1].position - b[1].position);
      
      for(const question of subQuestions) {
        const element = this._factory.createQuestion(question[1], this._id+"."+question[0]);
        const comp = new NestedComponent<T>(this._id, element.render(), question[1].condition as T);

        result.push(comp);
      }

    }

    return result;
  }

}
