import RenderNested from "core/classes/RenderNested";
import SimpleInputComponent from "../components/SimpleInput";

export default class SimpleInput extends RenderNested<string> {

  getComponent() {
    return SimpleInputComponent;
  }
  
}
