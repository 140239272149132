import React, { useEffect } from 'react';
import IMultipleChoice, { MultipleChoiceCondition } from 'core/render/questions/MultipleChoice';
import useI18n from 'i18n/useI18n';
import { Tab } from '@etiquette-ui/selectors';
import styled from 'styled-components';
import QuestionComponent from 'core/render/interfaces/QuestionComponent';
import AnimateTransition from 'components/Animate';
import IDComparisons from 'core/interfaces/IDComparisons';
import { useScroll } from 'providers/scroll';
import { cleanId } from 'core/utils';
import SectionTitle from 'components/SectionTitle';

const OptionsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 15px 0;
`;

const fulfilsCondition: (question: IMultipleChoice, response: any, condition: MultipleChoiceCondition) => boolean = (question, response, condition) => {
    if(condition.rule === IDComparisons.ID_NOT_EQUAL_TO) {
        return !response.includes(condition.value);
    }
    return response.includes(condition.value);
}

const MultipleChoice: React.FC<QuestionComponent<IMultipleChoice, MultipleChoiceCondition>> = ({ id, question, dataAccess, nested }) => {
    const { translateObject } = useI18n();
    const [response, setState] = dataAccess();
    const { registerQuestion } = useScroll();
    
    useEffect(()=>{
        registerQuestion(id, true);
        return () => {
            registerQuestion(id, false);
        }
    }, []);

    const onTabSelected = (id: string) => {
        if(response.includes(id)) {
            setState(response.filter((item: string)=>item!==id));
            return;
        }
        setState([...response, id]);
    }

    return (
        <AnimateTransition id={cleanId(id)}>
            <div>
            <SectionTitle style={{textAlign: 'center'}}>{translateObject(question.title)}</SectionTitle>
            <OptionsContainer>
                {question.options.map((option) => (
                    <Tab
                        key={option.id}
                        selected={response.includes(option.id)}
                        onClick={()=>onTabSelected(option.id)}
                        type='button'
                    >
                        {translateObject(option.translations)}
                    </Tab>
                ))}
            </OptionsContainer>
            </div>
            {nested.map(({ component, condition }) => {
                if (fulfilsCondition(question, response, condition)) {
                    return component;
                }
                return null;
            })}
        </AnimateTransition>
    );
};

export default MultipleChoice;
