import Axios from "axios";
import ISurvey, { Metadata } from "core/interfaces/ISurvey";
import Authorization from './auth';
import { executeCall } from "./utils";
import HashTable from "core/interfaces/HashTable";

export interface ISurveyAPI {
    options: {
        headers: unknown,
        url: string;
    },
    get: (uuid: string) => Promise<ISurvey>
    getAnswer: (search: HashTable<any>) => Promise<{ count: number }>
    getDelivered: (search: HashTable<any>) => Promise<{ count: number }>
    getPublic: (uuid: string) => Promise<ISurvey>
    metadata: (uuid: string) => Promise<Metadata>
    response: (uuid: string, data: any) => Promise<unknown>
    deliver: (uuid: string, data: any) => Promise<unknown>
    skip: (uuid: string, data: any) => Promise<unknown>
}

const crudder = (domain: string, resource: string, withAuth: boolean = true): ISurveyAPI => {
    const url = `${domain}/${resource}`;

    const headers = withAuth ? Authorization : {};

    return {
        options: {
            headers,
            url,
        },
        get: (uuid) => executeCall(() => Axios.get<ISurvey>(url + `/${uuid}`, { headers })),
        getAnswer: (search) => executeCall(() => Axios.post<{ count: number }>(url + '/answer/search', { search }, { headers })),
        getDelivered: (search) => executeCall(() => Axios.post<{ count: number }>(url + '/delivered/search', { search }, { headers })),
        getPublic: (uuid) => executeCall(() => Axios.get<ISurvey>(url + `/public/${uuid}`)),
        metadata: (uuid) => executeCall(() => Axios.get<Metadata>(url + `/metadata/${uuid}`, { headers })),
        response: (uuid, data) => executeCall(() => Axios.post(url, {...data, surveyId: uuid}, { headers })),
        deliver: (uuid, data) => executeCall(() => Axios.post(url + `/delivered`, {...data, surveyId: uuid}, { headers })),
        skip: (uuid, data) => executeCall(() => Axios.post(url + `/skipped`, {...data, surveyId: uuid}, { headers })),
    };
};

export default crudder;