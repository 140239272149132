import { FC, useEffect, useMemo } from "react";
import PreSurveyComponent from "core/presurvey/interfaces/PreSurveyComponent";
import Ticket from "./Ticket";
import usePreSurvey from "state/presurvey";
import SettingsType from "./types/SettingsType";
import { DataProvider } from "./data";
import { Container } from "./styles";
import useGlobalVariables from "state/globalVariables";
import { pathFinder } from "utils/objectUtils";

const Preform: FC<PreSurveyComponent<SettingsType>> = ({ id, settings }) => {
    const {
        state: { sections },
    } = usePreSurvey();
    const { dispatcher: globalVariablesDispatcher } = useGlobalVariables();

    const section = useMemo(() => {
        const section = sections[id];
        if (section?.type === "xetux-config") return section;
        return null;
    }, [sections, id]);

    useEffect(() => {
        if (!!settings.globalVariables && !!section) {            
            for (const key in settings.globalVariables) {
                globalVariablesDispatcher.setGlobalVariables(key, pathFinder(section, settings.globalVariables[key]));
            }
        }
    }, [section, globalVariablesDispatcher, settings.globalVariables]);

    return (
        <DataProvider
            employeesUrl={settings.employeesUrl}
            serverUserTypes={settings.serverUserTypes}
            ticketsUrl={settings.ticketUrl}
            section={section}
            id={id}
        >
            <Container>
                <Ticket id={id} section={section} />
            </Container>
        </DataProvider>
    );
};

export default Preform;
