import RenderNested from "core/classes/RenderNested";
import OptionsInComponent from "../components/OptionsIn";
import { OptionsInCondition } from "../questions/OptionsIn";

export default class OptionsIn extends RenderNested<OptionsInCondition> {

  getComponent() {
    return OptionsInComponent;
  }
  
}
