import { combineReducers } from 'redux';
import survey from './survey/reducer';
import settings from './settings/reducer';
import presurvey from './presurvey/reducer';
import globalVariables from './globalVariables/reducer';
import response from './response/reducer';

import { connectRouter } from 'connected-react-router';
import { History } from 'history';

const rootReducer = (history : History<unknown>) => combineReducers({
  survey,
  settings,
  presurvey,
  globalVariables,
  response,
  router: connectRouter(history),
});

export default rootReducer;
