import IQuestion from "../interfaces/IQuestion";
import Datable from "../interfaces/Datable";

export default abstract class Factory {
  data: Datable;

  constructor(data: Datable) {
    this.data = data;
  }

  public abstract createQuestion(question: IQuestion, id: string): any;
}
