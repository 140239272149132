export const APP_ID = process.env.REACT_APP_APP_ID ?? '';
export const GROUP_ID = process.env.REACT_APP_GROUP_ID ?? '';
export const DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG ?? 'es';

export const ACCESS_LIST = {
    PERMISSION: 'permission',
    DIVISION: 'division',
    ROLE: 'role',
    SALE: 'sale',
    GROUP: 'group',
    EMPLOYEE: 'employee',
    EMAIL_TEMPLATE: 'email-template',
}

export const ACCESS_USER_TYPES = {
    ROOT: 'root',
    GLOBAL: 'global',
    REGULAR: 'regular',
}