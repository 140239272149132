import React, { useEffect, useMemo } from 'react';
import Header from 'components/Header';
import styled from 'styled-components';
import { DISABLED_BUTTON } from '@etiquette-ui/colors';
import useI18n from 'i18n/useI18n';
import ContextDataAccess from 'core/classes/ContextDataAccess';
import PreviewFactory from 'core/render/PreviewFactory';
import Survey from 'core/classes/Survey';
import { Card } from '@etiquette-ui/misc';
import Layout from 'containers/Layout';
import SurveyContainer from 'containers/SurveyContainer';
import ProgressBar from './components/ProgressBar';
import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import { ScrollProvider } from '../../../../../../providers/scroll';
import SurveyBackground from './components/SurveyBackground';
import SurveyContent from './components/SurveyContent';
import SubmitButton from './containers/SubmitButton';
import useResponse from 'state/response';
import useSettings from 'state/settings';

import useWindowSize from 'hooks/useWindowSize';
import ISurvey from 'core/interfaces/ISurvey';
import HashTable from 'core/interfaces/HashTable';
import IAnswer from 'core/interfaces/IAnswer';
import IQuestion from 'core/interfaces/IQuestion';
import PreSurveyType from 'core/presurvey/interfaces/PreSurveyType';
import preSurveyConditionEvalCallbackFactory from 'core/presurvey/preSurveyConditionEvalCallbackFactory';
import usePreSurvey from 'state/presurvey';
import { useLocation } from 'react-router-dom';

const CustomCard = styled(Card)<{ $zIndex: number }>`
    padding: 0px 15px;
    position: relative;
    z-index: ${ ({$zIndex}) => ($zIndex ? $zIndex: 0) };
`;

const Overflow = styled.div`
    flex: 1;
    overflow-y: scroll;
`;

const QuestionContainer = styled.div`
    width: 100%;
    @media only screen and (min-width: 900px) {
        width: 80%;
    }
    @media only screen and (min-width: 1200px) {
        width: 60%;
    }
`;

const fulfilsPreSurveyCondition: (question: IQuestion, preSurveySections: HashTable<PreSurveyType>) => Boolean = (question, preSurveySections) => {
    if (!question.preSurveyCondtionConfig) return true;
    return preSurveyConditionEvalCallbackFactory(question.preSurveyCondtionConfig, preSurveySections);
};


const Main: React.FC<{survey: ISurvey, answers: HashTable<IAnswer> }> = ({ survey, answers }) => {
    const { translate, translateObject } = useI18n();
    const { dispatcher: responseDispatcher } = useResponse();
    const { state: preSurvey } = usePreSurvey();
    const { state: { progress }, dispatcher: settingsDispatcher } = useSettings();
    const location = useLocation();

    useEffect(()=>{
        const query = new URLSearchParams(location.search);
        const reference = query.get('reference');
        
        settingsDispatcher.setReference(reference);
    },[location.search, settingsDispatcher]);

    const data = useMemo(()=>new ContextDataAccess(answers, responseDispatcher.replaceResponse), [responseDispatcher, answers]);
    const previewFactory = useMemo(()=>new PreviewFactory(data), [data]);
    const surveyPreview = useMemo(()=>new Survey(survey, previewFactory), [survey, previewFactory]);

    const alert = useAlert();
    const [, windowHeight] = useWindowSize();

    const defaultSubmitButton = {
        'es': 'Terminar encuesta',
        'en': 'End survey'
    }

    const uploadSurvey = () => {
        settingsDispatcher.setSurveyCompleted(true);
        if(progress === 0) {
            responseDispatcher.skipStart();
        } else {
            responseDispatcher.sendStart();
        }
    };

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (progress < 100) {
            alert({
                type: ALERT_TYPES.CONFIRM,
                config: {
                    title: translate('Are you finished with the survey?'),
                    body: translate('You completed', { progress: progress }),
                    submitText: translate('No, go back'),
                    closeText: translate('Yes, I am done'),
                    showCloseButton: false,
                },
            })
                .then(() => {})
                .catch(() => {
                    uploadSurvey();
                });
        } else {
            uploadSurvey();
        }
    };

    const questions = useMemo(()=>surveyPreview.render().filter(({question})=>fulfilsPreSurveyCondition(question, preSurvey.sections)),[surveyPreview, preSurvey.sections]);
    return (
        <Layout height={ windowHeight }>
            <Header logoURL={survey?.style.logo ?? ''} simplified={true} />
            {!!survey?.showProgressBar &&  <ProgressBar surveyBackground={survey?.style.background} />}
            <ScrollProvider>
                <SurveyContainer  className={ survey?.showProgressBar ? '__withProgressBar' : '' } onSubmit={submit}>
                    <SurveyBackground backgroundColor={survey?.style.background}>
                        <Overflow>
                            <SurveyContent>
                                {questions.map((question, i: number) => (
                                    <React.Fragment key={question.id}>
                                        <CustomCard 
                                            $zIndex={-1*i}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}
                                            onClick={(e: any) => {
                                                if(i<questions.length-1){
                                                    e.currentTarget.nextSibling.nextSibling.style.zIndex = -1*(i+1);
                                                }
                                                if(i>0){
                                                    e.currentTarget.previousSibling.previousSibling.style.zIndex = -1*(i-1);
                                                }
                                                
                                                e.currentTarget.style.zIndex = 10;
                                            }}
                                            onBlur={(e: any) => {
                                                e.currentTarget.style.zIndex = -1*(i-1);
                                            }}
                                        >
                                            <QuestionContainer>{question.Component}</QuestionContainer>
                                        </CustomCard>
                                        {i < questions.length - 1 && (
                                            <hr style={{ border: `1px solid ${DISABLED_BUTTON}`, width: '65%', marginTop: 0, marginBottom: 0, zIndex: -1000, }} />
                                        )}
                                    </React.Fragment>
                                ))}
                            </SurveyContent>
                        </Overflow>
                    </SurveyBackground>
                    <SubmitButton>{translateObject(survey?.submitButton ?? defaultSubmitButton)}</SubmitButton>
                </SurveyContainer>
            </ScrollProvider>
        </Layout>
    );
};

export default Main;
