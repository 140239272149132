import { Dispatch, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { PreSurveyActions } from './actionTypes';
import Dispatcher from './dispatcher';
import selector from './selector';

const usePreSurvey = () => {
    const dispatch = useDispatch<Dispatch<PreSurveyActions>>();
    const dispatcher = useMemo(()=>new Dispatcher(dispatch), [dispatch]);
    const state = useSelector(selector, shallowEqual);

    return { state, dispatcher };
}

export default usePreSurvey;
