import RenderNested from "core/classes/RenderNested";
import MultipleChoiceComponent from "../components/MultipleChoice";
import { MultipleChoiceCondition } from "../questions/MultipleChoice";

export default class MultipleChoice extends RenderNested<MultipleChoiceCondition> {

  getComponent() {
    return MultipleChoiceComponent;
  }
  
}
