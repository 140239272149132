import { PreSurvey } from "core/interfaces/ISurvey";
import SectionType from "./interfaces/SectionType";
import preSurveyFactory from "./preSurveyFactory";

class Config {
    presurvey: PreSurvey;

    constructor(presurvey: PreSurvey) {
        this.presurvey = presurvey;
    }

    sections(): SectionType[] {
        const sections = [] as SectionType[];
        for (const key in this.presurvey) {
            const type = this.presurvey[key].type;
            sections.push({
                key,
                component: preSurveyFactory(type),
                settings: this.presurvey[key],
            });
        }
        return sections;
    }
}

export default Config;
