import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const AnimateContainer = styled.div`
  transition: opacity 0.3s, transform 0.3s;
  opacity: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 25px 15px;
  transform: translateY(100%);
  position: relative;
  z-index: 0;
  &.active {
    opacity: 1;
    transform: translateY(0);
  }

  & > & {
    padding: 10px 0px 0px 0px;
  }
`;

const AnimateTransition: React.FC<{ id?:string }> = ({id, children}) => {

  const [animate, setAnimate] = useState(false);

  useEffect(()=>{
    setAnimate(true);
  }, []);

  return (<AnimateContainer id={id} className={animate ? 'active' : ''}>{children}</AnimateContainer>);
}

export default AnimateTransition;
