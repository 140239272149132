const addObjectPath: (obj: { [key: string]: any }, is: string[], value: any) => any = (obj, is, value) => {
  return is.length > 1
      ? addObjectPath(
            obj.hasOwnProperty(is[0])
                ? obj[is[0]]
                : is.length === 1
                ? (obj[is[0]] = value)
                : (obj[is[0]] = {}),
            is.slice(1),
            value
        )
      : (obj[is[0]] = value);
};

export const addValueToObject: (obj: { [key: string]: any }, is: string, value: any) => any  = (obj, is, value) => {
  return addObjectPath(obj, is.split('.'), value);
};

const multiPath: (obj: { [key: string]: any }, is: string[]) => any = (obj, is) => {
  if (is.length) return multiPath(obj[is[0]], is.slice(1));
  return obj;
};

export const pathReferenceFinder: (obj: { [key: string]: any }, is: string) => any = (obj, is) => {
  try {
      return multiPath(obj, is.split('.'));
  } catch (e) {
      return '';
  }
};

export const pathFinder: (obj: { [key: string]: any }, is: string) => any = (obj, is) => {
  try {
      const ref = multiPath(obj, is.split('.'));

      if (Array.isArray(ref)) return [...ref];
      if (typeof ref === 'object') return { ...ref };

      return ref;
  } catch (e) {
      return '';
  }
};

export const flatTree: (obj: { [key:string]:any }) => { [key:string]:any } = (obj) => {
  let map = {} as { [key:string]:any };
  Object.keys(obj).forEach((key)=>{
    map[key] = { ...obj[key] };
    if(!!obj[key].nested) {
      const tmp = flatTree(obj[key].nested);
      map = {...map, ...tmp};
    }
  });

  return map;
}