import { DEFAULT_FETCHING_STATE, DEFAULT_NO_ERROR } from 'state/constants';
import GlobalVariablesState from './state';
import ACTION_STRINGS from './actionStrings';
import { DataActions } from './actionTypes';

const INITIAL_STATE: GlobalVariablesState = {
    variables: {},
    fetching: { ...DEFAULT_FETCHING_STATE },
    error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action: DataActions ) => {
    switch (action.type) {
      case ACTION_STRINGS.REPLACE_GLOBAL_VARIABLES: return { ...state, variables: action.payload, fetching: { ...DEFAULT_FETCHING_STATE } } as GlobalVariablesState
      case ACTION_STRINGS.SET_GLOBAL_VARIABLES: {
        const { key, value } = action.payload as { key: string, value: any }
        const data = {...state.variables};
        data[key] = value;
        return { ...state, variables: data, fetching: { ...DEFAULT_FETCHING_STATE } } as GlobalVariablesState
      }
      case ACTION_STRINGS.ADD_GLOBAL_VARIABLES: {
        const entries = action.payload as {[key: string]: any}
        const variables = {...state.variables, ...entries};
        return { ...state, variables: variables, fetching: { ...DEFAULT_FETCHING_STATE } } as GlobalVariablesState
      }

      case ACTION_STRINGS.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

      default: return state;
  }
};

export default reducer;