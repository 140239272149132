import Translation from 'core/render/interfaces/Translation';
import React, { useState, useEffect } from 'react';
import { processTranslations, getTranslation } from './helper';
import { DEFAULT_LANG } from 'resources/constants/config';
import useSettings from 'state/settings';

export type I18nContextType = {
    selectedLang: string;
    setSelectedLang: (lang: string) => void;
    translateObject: (value: Translation, vars?: { [key: string]: any } ) => string;
    translate: (value: string, vars?: { [key: string]: any }) => string;
};

const initialLang = localStorage.getItem('lang-code') ?? DEFAULT_LANG;

export const I18nContext = React.createContext<I18nContextType>({
    selectedLang: initialLang,
    setSelectedLang: (lang) => console.log(lang),
    translateObject: (value, vars) => `**${value}**`,
    translate: (value, vars) => `**${value}**`
});

const I18nProvider: React.FC<{ children: any }> = ({ children }) => {
    const [selectedLang, setSelectedLang] = useState(initialLang);
    const { dispatcher: settingsDispatcher } = useSettings();

    useEffect(()=>{
        settingsDispatcher.setSelectedLang(selectedLang);
    }, [selectedLang, settingsDispatcher]);

    useEffect(() => {
        setSelectedLang(initialLang);
    }, []);

    const values: I18nContextType = {
        selectedLang: selectedLang,
        setSelectedLang: (lang: string) => {
            setSelectedLang(lang);
            localStorage.setItem('lang-code', lang);
        },
        translateObject: (value: Translation, vars ) => processTranslations(value, vars, selectedLang),
        translate: (value, vars) => getTranslation(value, vars, selectedLang),
    };

    return (<I18nContext.Provider value={values}>{children}</I18nContext.Provider>);
};

export default I18nProvider;