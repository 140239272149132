import AnswerType from "./types/AnswerType";
import { isTicketResponse } from "./types/TicketResponse";

export default function formValidation(config: AnswerType | null, data: any): boolean {
  if(!config) return false;
  if(!data) return false;
  const { waiter, ticket } = config;
  const { ticketN, table, guests, amount } = ticket || {};

  if (!waiter || !ticketN || !table || !guests || !amount) {
    return false;
  }

  if (!data || !isTicketResponse(data)) {
    return false;
  }

  return true;
}
