import HashTable from 'core/interfaces/HashTable';
import IAnswer from 'core/interfaces/IAnswer';
import ISurvey from 'core/interfaces/ISurvey';
import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { getIP, surveyAPI } from 'resources/api';
import { AppState } from 'state/store';
import { processError } from 'state/utils';
import { deliverErrorAction, deliverSuccessAction, sendErrorAction, sendSuccessAction, skipErrorAction, skipSuccessAction } from './actions';
import ACTION_STRINGS from "./actionStrings";

function* sendStartAsync(): Generator<unknown, void, ISurvey | HashTable<IAnswer> | string> {
  try {
    const answers = (yield select((state: AppState) => state.response.answers)) as HashTable<IAnswer>;
    const presurvey = (yield select((state: AppState) => state.presurvey.sections)) as HashTable<any>;
    const globalVariables = (yield select((state: AppState) => state.globalVariables.variables)) as HashTable<any>;
    const uuid = (yield select((state: AppState) => state.survey.survey?._id)) as string;
    const fingerprint = (yield select((state: AppState) => state.survey.survey?.fingerprint)) as string;
    const selectedLang = (yield select((state: AppState) => state.settings.lang)) as string;
    const responseIP = (yield getIP()) as string

    yield surveyAPI.response(uuid, { fingerprint, answers, presurvey, globalVariables, metadata: { ip: responseIP, userAgent: window.navigator.userAgent, selectedLang } });
    yield put(sendSuccessAction());

  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put(sendErrorAction(message));
  }
}

function* sendStart() {
  yield takeLatest(ACTION_STRINGS.SEND_START, sendStartAsync);
}

function* deliverStartAsync(): Generator<unknown, void, ISurvey | HashTable<IAnswer> | string> {
  try {
    const presurvey = (yield select((state: AppState) => state.presurvey.sections)) as HashTable<any>;
    const globalVariables = (yield select((state: AppState) => state.globalVariables.variables)) as HashTable<any>;
    const uuid = (yield select((state: AppState) => state.survey.survey?._id)) as string;
    const fingerprint = (yield select((state: AppState) => state.survey.survey?.fingerprint)) as string;
    const selectedLang = (yield select((state: AppState) => state.settings.lang)) as string;
    const responseIP = (yield getIP()) as string

    yield surveyAPI.deliver(uuid, { fingerprint, presurvey, globalVariables, metadata: { ip: responseIP, userAgent: window.navigator.userAgent, selectedLang } });
    yield put(deliverSuccessAction());

  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put(deliverErrorAction(message));
  }
}

function* deliverStart() {
  yield takeLatest(ACTION_STRINGS.DELIVER_START, deliverStartAsync);
}

function* skipStartAsync(): Generator<unknown, void, ISurvey | HashTable<IAnswer> | string> {
  try {
    const presurvey = (yield select((state: AppState) => state.presurvey.sections)) as HashTable<any>;
    const globalVariables = (yield select((state: AppState) => state.globalVariables.variables)) as HashTable<any>;
    const uuid = (yield select((state: AppState) => state.survey.survey?._id)) as string;
    const fingerprint = (yield select((state: AppState) => state.survey.survey?.fingerprint)) as string;
    const selectedLang = (yield select((state: AppState) => state.settings.lang)) as string;
    const responseIP = (yield getIP()) as string

    yield surveyAPI.skip(uuid, { fingerprint, presurvey, globalVariables, metadata: { ip: responseIP, userAgent: window.navigator.userAgent, selectedLang } });
    yield put(skipSuccessAction());

  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put(skipErrorAction(message));
  }
}

function* skipStart() {
  yield takeLatest(ACTION_STRINGS.SKIP_START, skipStartAsync);
}

export default function* sagas() {
  yield all([call(sendStart), call(deliverStart), call(skipStart)]);
}
