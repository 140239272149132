import React, {useEffect} from 'react';
import IDateInput from 'core/render/questions/DateInput';
import QuestionComponent from 'core/render/interfaces/QuestionComponent';

import { DateInput as DateInputEttiquete } from "@etiquette-ui/inputs-dropdowns"

import "react-datepicker/dist/react-datepicker.css";

import { useScroll } from 'providers/scroll';
import useI18n from 'i18n/useI18n';
import { cleanId } from 'core/utils';
import { isAnswerEmpty } from 'core/utils/validation';
import styled from 'styled-components';

import { MONTHS } from 'resources/constants/months';

import AnimateTransition from 'components/Animate';
import SectionTitle from 'components/SectionTitle';

const CustomSelect = styled.select`
  border-radius: 3px;
  padding: 0px 8px;
  margin: 0px 5px;
  background: transparent;
  font-weight: 550;
`;

type HeaderProps = {
  date: any;
  changeYear: any;
  changeMonth: any;
  language: string;
}

const range = (start: number, stop: number, step: number = 1) =>
  Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)

const Header: React.FC<HeaderProps> = ({date, changeYear, changeMonth, language}) => {
  const now = new Date();
  const dateAssigned = new Date(date);
  const years = range(2015, now.getFullYear() + 1, 1);
  
  return (
    <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomSelect
            value={ dateAssigned.getMonth() }
            onChange={({ target: { value } }) => 
              changeMonth(+value)
            }
          >
            {MONTHS.map((option, i) => (
              <option key={option[language]} value={i}>
                {option[language]}
              </option>
            ))}
          </CustomSelect>
          <CustomSelect
            value={dateAssigned.getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </CustomSelect>
        </div>
  )
}



const DateInput: React.FC<QuestionComponent<IDateInput, string>> = ({id, question, dataAccess, nested}) => {
  const {translateObject, selectedLang} = useI18n();
  const [response, setResponse] = dataAccess();
  const { next, registerQuestion } = useScroll();

  useEffect(()=>{
    registerQuestion(id, true);
    return () => {
        registerQuestion(id, false);
    }
  }, []);

  useEffect(()=>{
    if(!isAnswerEmpty(response, question.type)){
      next(cleanId(id));
    }
}, [response]);

const getLimitDate = ( date: string ) => {
  switch(date){
    case 'today':
      return new Date();
    default:
      return new Date(date);
  }
}

  return (
    <AnimateTransition id={cleanId(id)}>
        <div>
        <SectionTitle style={{marginBottom: "1rem"}}>{translateObject(question.title)}</SectionTitle>
        <DateInputEttiquete 
          value={response} 
          setValue={setResponse} 
          language={selectedLang} 
          labelProps={{'label': translateObject(question.placeholder)}}
          calendarProps={{
            maxDate: !!question.maxDate ? getLimitDate(question.maxDate) : null,
            minDate: !!question.minDate ? getLimitDate(question.minDate) : null,
            renderCustomHeader: (props:HeaderProps) =>(
              <Header 
                date={props.date}
                changeYear={props.changeYear}
                changeMonth={props.changeMonth}
                language={selectedLang}
              />
            )
          }}  />
        </div>
        {nested.map(({component}) => {
            if (!!component) {
              return component;
            }
        })}
    </AnimateTransition>
  )
}
export default DateInput;