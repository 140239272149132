import PluginRenderer from "./PluginRenderer";
import IQuestion from "core/interfaces/IQuestion";
import PreviewFactory from "core/render/PreviewFactory";
import { PluginsList , plugins } from "./questions.dictionary";
import { answers } from "./answer.dictionary";


export const typeExists = (questionType: string) => questionType in plugins;

export const getQuestion = (
  questionType: keyof PluginsList,
  question: IQuestion,
  id: string,
  factory: PreviewFactory
) => {
  if (typeExists(questionType)) {
    const Renderer = new PluginRenderer(question, id, factory);
    const Component = plugins[questionType];
    Renderer.setComponent(Component);

    return Renderer;
  }

  throw new Error(`Unknown question type: ${questionType}`);
};

export const getAnswerDefault = (questionType: string) => {
  return answers[questionType]
};