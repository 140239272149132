import { BasicQuestionComponent } from "core/render/interfaces/QuestionComponent";
import React, { useEffect, useMemo } from "react";
import IXetuxProducts from "./types/IXetuxProducts";
import AnimateTransition from "components/Animate";
import { cleanId } from "core/utils";
import SectionTitle from "components/SectionTitle";
import useI18n from "i18n/useI18n";
import useGlobalVariables from "state/globalVariables";
import XetuxProduct from "./types/XetuxProduct";
import { useScroll } from "providers/scroll";
import { Subtitle } from "./styles";
import ProductRating from "./types/ProductRating";
import Products from "./Products";

const XetuxProductComponent: React.FC<BasicQuestionComponent<IXetuxProducts>> = ({
    id,
    question,
    dataAccess,
}) => {
    const { translateObject } = useI18n();
    const {
        state: { variables },
    } = useGlobalVariables();
    const { registerQuestion } = useScroll();
    const [response, setResponse] = dataAccess();

    const productsGlobalVariable = useMemo(()=>variables[question.globalVariable], [variables, question.globalVariable]);
    
    const list = useMemo(() => {
        const products = productsGlobalVariable.products as XetuxProduct[];
        const list = new Map<number, XetuxProduct>();
        products
            .filter((p) => p.family === question.family)
            .forEach((product) => list.set(product.id, product));

        return Array.from(list.values());
    }, [question.family, productsGlobalVariable]);

    useEffect(() => {
        registerQuestion(id, true);
        return () => {
            registerQuestion(id, false);
        };
    }, [id, registerQuestion]);

    useEffect(() => {
        const response: ProductRating[] = list.map((product) => ({
            id: product.id,
            posCode: product.posCode,
            rating: 0,
            comment: "",
        }));

        setResponse(response);
   }, [list]);
   
    return (
        <AnimateTransition id={cleanId(id)}>
            <div>
                {!!question.title && (
                    <SectionTitle style={{ textAlign: "center" }}>
                        {translateObject(question.title)}
                    </SectionTitle>
                )}
                {!!question.subtitle && (
                    <Subtitle style={{ textAlign: "center" }}>
                        {translateObject(question.subtitle)}
                    </Subtitle>
                )}
                {list.length > 0 && response.length > 0 && (
                    <Products
                        question={question}
                        response={response}
                        setResponse={setResponse}
                        list={list}
                        questionId={id}
                    />
                )}
            </div>
        </AnimateTransition>
    );
};

export default XetuxProductComponent;
