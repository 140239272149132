import IDComparisons from "core/interfaces/IDComparisons";
import PreSurveyConditionEvalCallback from "../interfaces/PreSurveyConditionEvalCallback";

export interface EmployeeSelectConditionRule { 
    rule: IDComparisons;
    value: string | number | null;
 }

const evalEmployeeSelect: PreSurveyConditionEvalCallback<EmployeeSelectConditionRule> = (condition, key, preSurveySections) => {
    const variable = preSurveySections[key];
    if(!variable || variable.type !== 'employee-select') return true;

    if(condition.rule === 'id') return variable.id === condition.value;
    if(condition.rule === 'nid') return variable.id !== condition.value;
    
    return false;
}

export default evalEmployeeSelect;