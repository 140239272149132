import React, { useEffect } from 'react';
import IOptionsIn, { OptionsInCondition } from 'core/render/questions/OptionsIn';
import QuestionComponent from 'core/render/interfaces/QuestionComponent';
import Option from 'core/render/interfaces/Option';
import useI18n from "i18n/useI18n";
import {Dropdown} from "@etiquette-ui/inputs-dropdowns";
import {cleanId, compliesWithRule} from "core/utils";
import AnimateTransition from 'components/Animate';
import { useScroll } from 'providers/scroll';
import SectionTitle from 'components/SectionTitle';
import { isAnswerEmpty } from 'core/utils/validation';
import styled from 'styled-components';
import useGlobalVariables from 'state/globalVariables';

const DropdownContainer = styled.div`
    width: 50%;
    min-width: 250px;
    max-width: 300px;
    margin: auto;

    .selector {
        &__option {
            text-align: center;
            font-size: 17px;
            font-weight: 400;
            padding-top: 13px;
            padding-bottom: 13px;
        }
    }

`;

const fulfilsCondition: (question: IOptionsIn, response: any, condition: OptionsInCondition) => boolean = (question, response, condition) => {
    const resValue = condition.rule ==='id' || condition.rule ==='nid' ? response : question.options.find((opt)=> opt.id === response)?.value;
    const {rule, value: expected} = condition
    return compliesWithRule(rule, resValue, expected)
}

const OptionsIn: React.FC<QuestionComponent<IOptionsIn, OptionsInCondition>> = ({ id, question, dataAccess, nested }) => {
    const {translateObject} = useI18n();
    const [ value, setValue] = dataAccess();
    const { next, registerQuestion } = useScroll();
    const { dispatcher: globalVariablesDispatcher } = useGlobalVariables();
    
    useEffect(()=>{
        registerQuestion(id, true);
        return () => {
            registerQuestion(id, false);
        }
    }, []);

    const options = question.options.map(({translations, value}, index) => ({
        label: translateObject(translations),
        value: index
    }))

    const setOptions = (option: Option ) => ( option.value !== null && +option.value >= 0) && setValue(question.options[option.value].id);

    useEffect(()=>{
        if(!!question.globalVariable && !!value) {
            const divisionValue = question.options.filter( (option: Option) => option.id === value );
            globalVariablesDispatcher.setGlobalVariables(question.globalVariable, divisionValue[0].value);
        }
        if(!isAnswerEmpty(value, question.type)){
            next(cleanId(id));
        }
    }, [value]);

    return (
        <AnimateTransition id={cleanId(id)}>
            <div>
                <SectionTitle style={{marginBottom: "1rem"}} >{translateObject(question.title)}</SectionTitle>
                <DropdownContainer>
                    <Dropdown
                        containerProps={{
                            style:{
                                background: !!value ? 'rgba(222, 235, 255, .3)' : 'var(--bg)',
                            }
                        }}
                        selectProps={{
                            onChange: setOptions,
                            options,
                            placeholder: !!question.placeholder ? translateObject(question.placeholder) : '',
                            styles: {
                                singleValue: (provided: any) => ({
                                    ...provided,
                                    marginTop: '3px!important',
                                    fontWeight: '600'
                                }),
                                option: (provided: any) => ({
                                    ...provided,
                                    fontWeight: '400'
                                }),
                                control: () => ({
                                    pointerEvents: 'none',
                                    background: 'transparent!important',
                                }),
                                placeholder: (provided: any) => ({
                                    ...provided,
                                    marginTop: '3px',
                                })
                            }
                            
                        }}
                        selectedValues={options}
                        leadingIconProps={{
                            icon: question.icon || ''
                        }}
                    />
                </DropdownContainer>
            
            </div>
            {nested.map(({ component, condition , id}, i) => {
                if (fulfilsCondition(question, value, condition)) {
                    return <React.Fragment key={`${i}-${id}`}>{component}</React.Fragment>;
                }
                return null;
            })}
        </AnimateTransition>
    );
};

export default OptionsIn;
