import Translation from "core/render/interfaces/Translation";

export const tableLabel: Translation = {
  en: "Table",
  es: "Mesa",
};

export const waiterLabel: Translation = {
  en: "Waiter",
  es: "Mesero",
};

export const waiterPlaceholder: Translation = {
  en: "Select",
  es: "Seleccionar",
};

export const amountLabel: Translation = {
  en: "Amount",
  es: "Cantidad",
};

export const guestLabel: Translation = {
  en: "Guests",
  es: "Comensales",
};

export const managerLabel: Translation = {
  en: "Manager",
  es: "Gerente",
};

export const operatorLabel: Translation = {
  en: "Asistant Manager",
  es: "Operador",
};

export const selectOptionLabel: Translation = {
  en: "Select an option",
  es: "Selecciona una opción",
};

export const waiterGuestsLabel: Translation = {
  en: "Confirm number of guests (waiter reports ",
  es: "Confirmar número de asistentes (mesero reporta ",
};
