import ISurvey from "../interfaces/ISurvey";
import Factory from "./BaseFactory";
import Rendereable from "../interfaces/Rendereable";
import IQuestion from "core/interfaces/IQuestion";

export default class Survey implements Rendereable {
  survey: ISurvey;
  protected _factory : Factory;

  constructor(survey: ISurvey, factory: Factory) {
    this.survey = survey;
    this._factory = factory;
  }

  render(): { Component: React.ReactNode, question: IQuestion, id: string }[] {
    const result = Object.entries(this.survey.questions).sort((a,b)=>a[1].position - b[1].position).map((question) => {
      const element = this._factory.createQuestion(question[1], question[0]);
      return {Component: element.render(), question: question[1], id: question[0]};
    });

    return result;
  }
}
