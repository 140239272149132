import React from "react";

export default class NestedComponent<T> {
    id: string;
    condition: T;
    component: React.ReactNode;

    constructor(id: string, component : React.ReactNode , condition: T ) {
        this.id = id;
        this.condition = condition;
        this.component = component;
      }
}