import styled from "styled-components";

export const Subtitle = styled.p`
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
`;

export const Rated = styled.div`
    width: 100%;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const ProductContainer = styled.div`
    display: inline-block;
    margin: 10px 10px 15px 10px;
`;

export const ProductTitle = styled.h3`
    text-align: center;
    font-size: large;
    font-weight: 500;
    padding: 0px;
    height: 4rem;
    margin: 0;
    margin-bottom: 20px;
    opacity: 1;
    display: block;
    padding: 0.5rem;
    border-radius: 8px;
    line-height: normal;
    background: #eefbff 0% 0% no-repeat padding-box;
`;

export const ProductList = styled.div`
    display: flex;
    justify-content: space-between;
    overflow-x: scroll;
    gap: 5px;
`;

export const ProductDescription = styled.p`
    text-align: center;
    padding-top: 10px;
    padding-bottom: 1rem;
    margin: 0;
`;

export const ProductImageContainer = styled.div`
    min-height: 15rem;
    img {
        height: 15rem;
    }
`;
