import React, { useEffect } from 'react';
import ISimpleInput from 'core/render/questions/SimpleInput';
import useI18n from 'i18n/useI18n';
import {Input} from '@etiquette-ui/inputs-dropdowns';
import QuestionComponent from 'core/render/interfaces/QuestionComponent';
import AnimateTransition from 'components/Animate';
import { useScroll } from 'providers/scroll';
import { cleanId } from 'core/utils';
import SectionTitle from 'components/SectionTitle';
import styled from 'styled-components';

const CustomInput = styled(Input)`
    padding: 12px;
    height: auto;
`;

const SimpleInput: React.FC<QuestionComponent<ISimpleInput, string>> = ({id, question, dataAccess, nested}) => {
    const {translateObject} = useI18n();
    const [response, setResponse] = dataAccess();
    const { registerQuestion } = useScroll();
    
    useEffect(()=>{
        registerQuestion(id, true);
        return () => {
            registerQuestion(id, false);
        }
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setResponse(event.target.value)

    return (
        <AnimateTransition id={cleanId(id)}>
            <div>
            <SectionTitle style={{marginBottom: "1rem"}}>{translateObject(question.title)}</SectionTitle>
            <CustomInput
                inputProps={{
                    onChange: handleChange,
                    value: response,
                    placeholder: translateObject(question.placeholder)
                }}
                iconProps={{
                    icon: ''
                }}
            />
            </div>
            {nested.map(({component, condition}) => {
                if ((condition === '*' && response) || condition === response) {
                    return component;
                }
                return null;
            })}
        </AnimateTransition>
    );
};

export default SimpleInput;
