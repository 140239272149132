import Translation, { isTranslation } from "core/render/interfaces/Translation";

export type Category = {
    [key: string]: any;
    id: number;
    translations: {
        title: Translation;
    };
};

export type Product = {
    id: number;
    posCode: string;
    translations: {
        title: Translation;
        description: Translation;
    };
    categories: Category[];
    img: string;
    family: string | null;
    posCategory: string;
};

export type TicketConfig = {
    date: string;
    ticketN: string;
    table: {
        id: string;
        name: string;
    };
    guests: number;
    amount: number;
    waiter: {
        id: string;
        name: string;
    };
};

type TicketResponse = {
    config: TicketConfig;
    products: Product[];
};

function isCategory(obj: any): obj is Category {
    if (!obj) return false;
    return typeof obj.id === "number" && obj.translations && isTranslation(obj.translations.title);
}

function isProduct(obj: any): obj is Product {
    if (!obj) return false;
    return (
        typeof obj.id === "number" &&
        typeof obj.posCode === "string" &&
        obj.translations &&
        isTranslation(obj.translations.title) &&
        isTranslation(obj.translations.description) &&
        Array.isArray(obj.categories) &&
        obj.categories.every(isCategory) &&
        (typeof obj.img === "string" || obj.img === null) &&
        (typeof obj.family === "string" || obj.family === null) &&
        typeof obj.posCategory === "string"
    );
}

function isTicketConfig(obj: any): obj is TicketConfig {
    if (!obj) return false;
    return (
        typeof obj.date === "string" &&
        typeof obj.ticketN === "string" &&
        obj.table &&
        typeof obj.table.id === "string" &&
        typeof obj.table.name === "string" &&
        typeof obj.guests === "number" &&
        typeof obj.amount === "number" &&
        obj.waiter &&
        typeof obj.waiter.id === "string" &&
        typeof obj.waiter.name === "string"
    );
}

export function isTicketResponse(obj: any): obj is TicketResponse {
    if (!obj) return false;
    return (
        obj &&
        isTicketConfig(obj.config) &&
        Array.isArray(obj.products) &&
        obj.products.every(isProduct)
    );
}

export default TicketResponse;
