import styled from 'styled-components';

const SurveyContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 30px 70px 30px;
    position: relative;
    gap: 30px;
    transform: translateZ(0);
    transform-style: preserve-3d;
`;

export default SurveyContent;
