import styled from 'styled-components';

const RaitingContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 450px;

    @media( min-width: 500px ){
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    @media( min-width: 768px ){
        width: 60%;
    }
`;

export default RaitingContainer;