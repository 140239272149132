import { Dispatch } from "react";
import { ResponseActions } from "./actionTypes";
import { createValidationTreeAction, deliverStartAction, replaceResponseAction, resetError, sendStartAction, skipStartAction } from "./actions";

export default class ResponseDispatcher {
    _dispatch: Dispatch<ResponseActions>;

    constructor(dispatch: Dispatch<ResponseActions>) {
        this._dispatch = dispatch;
    }

    sendStart = () => this._dispatch(sendStartAction());

    replaceResponse = (response: {[key: string]: any}) => this._dispatch(replaceResponseAction(response));

    createValidationTree = (response: {[key: string]: any}) => this._dispatch(createValidationTreeAction(response));

    deliverStart = () => this._dispatch(deliverStartAction());

    skipStart = () => this._dispatch(skipStartAction());

    resetError = () => this._dispatch(resetError());
}
