import { ResetError } from 'state/interfaces/ResetError';
import ACTION_STRINGS from './actionStrings';
import { CreateValidationTree, ReplaceResponse, APIError, SendStart, SendSuccess, DeliverStart, DeliverSuccess, SkipStart, SkipSuccess } from './actionTypes';

export const replaceResponseAction = (payload: {[key: string]: any}): ReplaceResponse => ({
    type: ACTION_STRINGS.REPLACE_RESPONSE,
    payload,
});

export const createValidationTreeAction = (payload: {[key: string]: any}): CreateValidationTree => ({
    type: ACTION_STRINGS.CREATE_VALIDATION_TREE,
    payload,
});

export const sendStartAction = (): SendStart => ({
    type: ACTION_STRINGS.SEND_START,
    payload: null,
});

export const sendSuccessAction = (): SendSuccess => ({
    type: ACTION_STRINGS.SEND_SUCCESS,
    payload: null,
});

export const sendErrorAction = (payload: any): APIError => ({
    type: ACTION_STRINGS.SEND_ERROR,
    payload,
});

export const deliverStartAction = (): DeliverStart => ({
    type: ACTION_STRINGS.DELIVER_START,
    payload: null,
});

export const deliverSuccessAction = (): DeliverSuccess => ({
    type: ACTION_STRINGS.DELIVER_SUCCESS,
    payload: null,
});

export const deliverErrorAction = (payload: any): APIError => ({
    type: ACTION_STRINGS.DELIVER_ERROR,
    payload,
});

export const skipStartAction = (): SkipStart => ({
    type: ACTION_STRINGS.SKIP_START,
    payload: null,
});

export const skipSuccessAction = (): SkipSuccess => ({
    type: ACTION_STRINGS.SKIP_SUCCESS,
    payload: null,
});

export const skipErrorAction = (payload: any): APIError => ({
    type: ACTION_STRINGS.SKIP_ERROR,
    payload,
});

export const resetError = (): ResetError<ACTION_STRINGS> => ({
    type: ACTION_STRINGS.RESET_ERROR,
    payload: {}
});