import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import TicketResponse from "../types/TicketResponse";
import { createAnswer, createTicket, createWaiterOption } from "./utils";
import usePreSurvey from "state/presurvey";
import useGlobalVariables from "state/globalVariables";
import { surveyAPI } from "resources/api";

const useTicket = (id: string, url: string, ticketN?: number | null) => {
    const [loading, setLoading] = useState(false);
    const { dispatcher: preSurveyDispatcher } = usePreSurvey();
    const { dispatcher: globalVariablesDispatcher } = useGlobalVariables();

    const fetchTicket = useCallback(async () => {
        setLoading(true);
        try {
            const res = await axios.get<TicketResponse>(`${url}/${ticketN}`);
            const { amount, guests, table, waiter, ticketN: ticketId } = res.data.config;

            const payload = { [`globalVariables.${id}.config.ticketN`]: ticketId };
            const [ answers, delivered ] = await Promise.all([surveyAPI.getAnswer(payload), surveyAPI.getDelivered(payload)]);
            
            if(answers.count > 0 || delivered.count > 0) throw new Error('Ticket already active');
            
            const ticket = createTicket(ticketN, table.name, guests, amount);
            const waiterOption = createWaiterOption(waiter.name, waiter.id);
            const newAnswer = createAnswer(ticket, waiterOption, res.data.products);
            
            preSurveyDispatcher.setPreSurvey(id, newAnswer);

            globalVariablesDispatcher.setGlobalVariables(id, res.data);
        } catch (error) {
            let errorMessage = "Unknown error";
            
            const newSection = createAnswer();
            newSection.ticket = createTicket(ticketN);
            preSurveyDispatcher.setPreSurvey(id, newSection);

            if(error instanceof Error && !!error.message) {
                errorMessage = error.message;
            }

            if (axios.isAxiosError(error)) {
                if (error.response && error.response.data && error.response.data) {
                    errorMessage = error.response.data.message;
                } else {
                    errorMessage = error.message;
                }
            }
            
            alert(errorMessage);
        } finally {
            setLoading(false);
        }
    }, [globalVariablesDispatcher, id, preSurveyDispatcher, ticketN, url]);

    useEffect(() => {
        if (!ticketN || !url) return;

        const delayDebounceFn = setTimeout(fetchTicket, 1250);

        return () => clearTimeout(delayDebounceFn);
    }, [id, url, ticketN, preSurveyDispatcher, globalVariablesDispatcher, fetchTicket]);

    return { loading };
};

export default useTicket;
