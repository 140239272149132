import React, { useEffect, useRef } from 'react';
import IForm, { Field, Options } from 'core/render/questions/Form';
import { BasicQuestionComponent } from '../interfaces/QuestionComponent';
import useI18n from 'i18n/useI18n';
import { Input, PhoneInput, utils } from '@etiquette-ui/inputs-dropdowns';
import { RadioButton, Checkbox } from '@etiquette-ui/selectors';
import styled from 'styled-components';
import AnimateTransition from 'components/Animate';
import { cleanId } from 'core/utils';
import SectionTitle from 'components/SectionTitle';
import { useScroll } from 'providers/scroll';

const FieldContainer = styled.div`
    margin: 15px 0;

    svg {
        filter: brightness(0);
    }
`;

const FieldComponent: React.FC<{ field: Field | Options; dataAccess: [any, (d: any) => void] }> = ({ field, dataAccess }) => {
    const { translateObject, translate } = useI18n();
    const [state, setState] = dataAccess;
    const phoneRef = useRef<HTMLInputElement>(null);

    const onChangePhoneInput = (e: any) => {
        const value = e.target.value;

        setState({ ...state, phone: value });
        if (!phoneRef.current) return;

        if (!value && !!field.isRequired) {
            phoneRef.current.setCustomValidity(translate('Invalid Phone Number'));
            phoneRef.current.reportValidity();
            return;
        }

        if (!value && !field.isRequired) {
            phoneRef.current.setCustomValidity('');
            return;
        }

        const countryCode = utils.findCountryPhoneCode(state.countryCode);
        if (!countryCode) {
            phoneRef.current.setCustomValidity('');
            return;
        }
        if (utils.isValidFullNumber(`+${countryCode}${value}`)) {
            phoneRef.current.setCustomValidity('');
        } else {
            phoneRef.current.setCustomValidity(translate('Invalid Phone Number'));
            phoneRef.current.reportValidity();
        }
    };

    switch (field.type) {
        case 'email':
        case 'name':
        case 'open':
            return (
                <FieldContainer>
                    <Input
                        iconProps={{
                            icon: field.icon,
                        }}
                        inputProps={{
                            onChange: (e: any) => {
                                setState(e.target.value);
                            },
                            value: state,
                            required: !!field.isRequired,
                            pattern: field.type === 'email' ? '[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,12}$' : undefined,
                        }}
                        labelProps={{
                            label: translateObject(field.placeholder),
                        }}
                    />
                </FieldContainer>
            );
        case 'phone':
            return (
                <FieldContainer>
                    <PhoneInput
                        ref={phoneRef}
                        countrySelectProps={{
                            countriesLabel: translate('All Countries'),
                            country: state.countryCode,
                            onChange: (country: string) => {
                                setState({ ...state, countryCode: country });
                            },
                            preferredCountriesCodes: ['MX', 'US', 'CA'],
                        }}
                        iconProps={{
                            icon: field.icon,
                        }}
                        inputProps={{
                            onChange: onChangePhoneInput,
                            value: state.phone,
                            required: !!field.isRequired,
                        }}
                        labelProps={{
                            label: translateObject(field.placeholder),
                        }}
                    />
                </FieldContainer>
            );
        case 'radio':
            return (
                <FieldContainer style={{ display: 'flex', gap: '15px', padding: '5px 0' }}>
                    {field.options.map((opt) => (
                        <RadioButton
                            key={opt.id}
                            checked={state === opt.id}
                            onClick={() => setState(opt.id)}
                            inputProps={{ name: !!field.isRequired ? field.id : undefined, required: !!field.isRequired }}
                        >
                            {translateObject(opt.translations)}
                        </RadioButton>
                    ))}
                </FieldContainer>
            );
        case 'checkbox':
            return (
                <FieldContainer style={{ display: 'flex', gap: '15px', padding: '5px 0' }}>
                    {field.options.map((opt) => (
                        <Checkbox
                            key={opt.id}
                            checked={state.includes(opt.id)}
                            onClick={() => {
                                if (state.includes(opt.id)) {
                                    setState(state.filter((item: string) => item !== opt.id));
                                    return;
                                }
                                setState([...state, opt.id]);
                            }}
                            inputProps={{ name: !!field.isRequired ? field.id : undefined, required: !!field.isRequired }}
                        >
                            {translateObject(opt.translations)}
                        </Checkbox>
                    ))}
                </FieldContainer>
            );
        default:
            return <div></div>;
    }
};

const Form: React.FC<BasicQuestionComponent<IForm>> = ({ id, question, dataAccess }) => {
    const { translateObject } = useI18n();
    const { registerQuestion } = useScroll();

    useEffect(() => {
        registerQuestion(id, true);
        return () => {
            registerQuestion(id, false);
        };
    }, []);

    return (
        <AnimateTransition id={cleanId(id)}>
            {question.blocks.map((block, i) => (
                <div key={i}>
                    {block.title !== null && (
                        <SectionTitle style={{ textAlign: 'center' }}>{translateObject(block.title)}</SectionTitle>
                    )}
                    {block.fields.map((field) => (
                        <FieldComponent key={field.id} field={field} dataAccess={dataAccess(field.id)} />
                    ))}
                </div>
            ))}
        </AnimateTransition>
    );
};

export default Form;
