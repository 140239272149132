import PreSurveyConditionEvalCallback from "core/presurvey/interfaces/PreSurveyConditionEvalCallback";
import EvalCondition from "../types/EvalCondition";

const evalConditionCallback: PreSurveyConditionEvalCallback<EvalCondition> = (condition, key, preSurveySections) => {
    const variable = preSurveySections[key];
    if(!variable || variable.type !== 'toast-config') return true;

    if(condition.key === 'products' && condition.rule === 'drink') return variable.products.filter((p)=>p.family === 'drink').length > 0;

    if(condition.key === 'products' && condition.rule === 'food') return variable.products.filter((p)=>p.family === 'food').length > 0;
    
    return false;
}

export default evalConditionCallback;