import React, { useEffect, useState } from 'react';
import IStaffOptions from 'core/render/questions/StaffOptions';
import useI18n from 'i18n/useI18n';
import { P } from '@etiquette-ui/typography';
import { PRIMARY } from '@etiquette-ui/colors';
import QuestionComponent from 'core/render/interfaces/QuestionComponent';
import { cleanId } from 'core/utils';
import AnimateTransition from 'components/Animate';
import { getUsersOfGroup } from 'resources/api';
import { useScroll } from 'providers/scroll';
import styled from 'styled-components';
import { ADMIN_IMAGES_URL } from 'resources/constants/urls';
import AllMembersImage from 'resources/img/all_members.png';
import DefaultProfileImage from 'resources/img/default_profile.png';
import SectionTitle from 'components/SectionTitle';
import useSurvey from 'state/survey';
import { isAnswerEmpty } from 'core/utils/validation';
import useGlobalVariables from 'state/globalVariables';

interface User {
    id: number;
    name: string;
    secondName: string | null;
    isActive: number;
    userPosId: string | number | null;
    image: string | null;
}

const UsersGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 15px;
`;

const Instruction =  styled.p`
    font-size: 16px;
    text-align: center;
    margin-top: -10px;
    margin-bottom: -5px;
`;

const UserContainer = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
    gap: 10px;
    user-select: none;
    cursor: pointer;
    max-width: 33.333333%;
    flex: 0 0 33.333333%;

    figure, img,
    .iconContainer {
        border-radius: 50%;
        min-width: 71px;
        min-height: 71px;
    }

    figure{
        width: fit-content;
        margin: auto;
        max-width: 100%;
        overflow: hidden;
        transform:  ${({ active }) => (active ? 'scale(1.06)' : 'scale(1)')};
        transition-duration: .4s;
    }

    .iconContainer, img {
        border: solid 5px ${({ active }) => (active ? PRIMARY : 'transparent')};
    }

    .iconContainer {
        background: red;
    }

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        object-fit: cover;
    }

    p {
        text-align: center;
    }

    @media(  min-width: 451px){
        padding: 10px;
        max-width: 25%;
        flex: 0 0 25%;

        figure, img {
            min-width: 80px;
        }

        img {
            max-width: 84px;
        }
    }
`;

const UserBox: React.FC<{ id: number; imageURL: string; name: string; active: boolean; onClick: () => void }> = ({
    id,
    imageURL,
    name,
    active,
    onClick,
}) => {
    return (
        <UserContainer active={active} onClick={onClick}>
            <figure>
                {imageURL && (<img src={imageURL} alt={`user_${id}`} />)}
            </figure>
            
            <P>{name}</P>
        </UserContainer>
    );
};

const StaffOptions: React.FC<QuestionComponent<IStaffOptions, null>> = ({ id, question, dataAccess, nested }) => {
    const { translateObject, translate } = useI18n();
    const [state, setState] = dataAccess();
    const [users, setUsers] = useState([] as User[]);
    const { state: { survey } } = useSurvey();
    const { next, registerQuestion } = useScroll();
    const { state: upperState } = useGlobalVariables();

    const getDivision = () => {
        return (typeof question.division === "string" ) 
            ?  upperState.variables[question.division]
            : question.division;
    }

    useEffect(() => {
        registerQuestion(id, true);
        return () => {
            registerQuestion(id, false);
        };
    }, []);

    useEffect(() => {
        if (!survey) return;

        getUsersOfGroup({ groupId: survey.meta.groupId, division: getDivision(), role: question.role })
            .then((res: any) => {
                const users = [] as User[];
                res.forEach((u: User) => {
                    users.push({ ...u, image: u.image ? `${ADMIN_IMAGES_URL}${u.image}` : '' });
                });
                if (question.includeAllTeamMembers) {
                    users.push({
                        name: translate('All Team Members'),
                        image: AllMembersImage,
                        id: -1,
                        secondName: null,
                        isActive: 1,
                        userPosId: null,
                    });
                }
                setUsers(users);
            })
            .catch((e: any) => console.error(e));
    }, [getDivision()]);

    useEffect(() => {
        if (!isAnswerEmpty(state, question.type)) {
            next(cleanId(id));
        }
    }, [state.userId]);

    return (
        <AnimateTransition id={cleanId(id)}>
            <div>
                <SectionTitle style={{ textAlign: 'center' }}>{translateObject(question.title)}</SectionTitle>
                {question.instructions &&(<Instruction>{translateObject(question.instructions)}</Instruction>)}
                <UsersGrid>
                    {users.map((user) => (
                        <UserBox
                            key={user.id}
                            onClick={() => {
                                setState({ ...state, userId: user.id });
                            }}
                            active={user.id === state.userId}
                            imageURL={!!user.image ? user.image : DefaultProfileImage}
                            id={user.id}
                            name={user.name}
                        />
                    ))}
                </UsersGrid>
            </div>
            {nested.map(({ component, id }, i) => {
                    return <React.Fragment key={`${i}-${id}`}>{component}</React.Fragment>;
            })}
        </AnimateTransition>
    );
};

export default StaffOptions;
