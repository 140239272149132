import RenderNested from "core/classes/RenderNested";
import TopicsComponent from "../components/Topics";
import { TopicsCondition } from "../questions/Topics";

export default class Topics extends RenderNested<TopicsCondition> {

  getComponent() {
    return TopicsComponent;
  }
  
}
