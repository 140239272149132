import { createElement } from "react";
import Rendereable from "../interfaces/Rendereable";
import IQuestion from "../interfaces/IQuestion";
import Factory from "./BaseFactory";
import NestedComponent from "core/classes/NestedComponent";
export default abstract class Renderer implements Rendereable {
  protected _question: IQuestion;
  protected _id : string;
  protected _factory : Factory;

  constructor(question: IQuestion , id : string, factory : Factory ) {
    this._question = question;
    this._id = id;
    this._factory = factory;
  }

  abstract getComponent(): any;

  getDataAccessor(key?: string) : [any, (d: any) => void] {
    const path = this._id.split('.').join('.nested.');
    return this._factory.data.getDataAccessor(`${path}.value${key ? '.' + key : '' }`);
  };

  render() {
    const element = this._factory.createQuestion(this._question, this._id);

    const props = {
      key: this._id,
      id: this._id,
      question: this._question,
      dataAccess : this.getDataAccessor.bind(this),
      nested: [] as NestedComponent<any>[],
    };
    
    return createElement(element.getComponent(), props);
  }

}
