import { P } from '@etiquette-ui/typography';
import styled from 'styled-components';

const SectionTitle = styled(P)`
    text-align: center;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 20px;
    line-height: normal;
`;

export default SectionTitle;