enum ACTION_STRINGS {
    REPLACE_RESPONSE = "[RESPONSE] REPLACE",
    CREATE_VALIDATION_TREE = "[RESPONSE] CREATE VALIDATION TREE",

    SEND_START = "[RESPONSE] SEND START",
    SEND_SUCCESS = "[RESPONSE] SEND SUCCESS",
    SEND_ERROR = "[RESPONSE] SEND ERROR",
    
    DELIVER_START = "[RESPONSE] DELIVER START",
    DELIVER_SUCCESS = "[RESPONSE] DELIVER SUCCESS",
    DELIVER_ERROR = "[RESPONSE] DELIVER ERROR",

    SKIP_START = "[RESPONSE] SKIP START",
    SKIP_SUCCESS = "[RESPONSE] SKIP SUCCESS",
    SKIP_ERROR = "[RESPONSE] SKIP ERROR",

    RESET_ERROR = "[RESPONSE] RESET ERROR",
}

export default ACTION_STRINGS;