import Translation from "core/render/interfaces/Translation";

export const tableLabel: Translation = {
  en: "Table",
  es: "Mesa",
};

export const waiterLabel: Translation = {
  en: "Waiter",
  es: "Mesero",
};

export const waiterPlaceholder: Translation = {
  en: "Select",
  es: "Seleccionar",
};

export const amountLabel: Translation = {
  en: "Amount",
  es: "Cantidad",
};

export const guestLabel: Translation = {
  en: "Guests",
  es: "Comensales",
};