import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 500px;

    @media (min-width: 768px) {
        width: 70%;
        gap: 2rem;
    }

    @media (min-width: 451px) and (max-width: 768px) {
        gap: 30px;
    }
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: -8px;
    margin-right: -8px;

    @media (min-width: 768px) {
        margin-left: -15px;
        margin-right: -15px;
    }
`;

const Column = styled.div`
    padding-left: 8px;
    padding-right: 8px;

    @media (min-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
    }
`;

export const FullColumn = styled(Column)`
    max-width: 1000%;
    flex: 0 0 100%;
`;

export const HalfColum = styled(Column)`
    max-width: 50%;
    flex: 0 0 50%;
`;
