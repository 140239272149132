import React from 'react';
import VERSION_CODE from 'resources/constants/version';
import styled from 'styled-components';
import LangSelect from './components/LangSelect';
import Logout from './components/Logout';
import User from './components/User';
import { P } from '@etiquette-ui/typography';
import { BG } from '@etiquette-ui/colors';

const Container = styled.div`
    width: 100%;
    max-width: 1124px;
    margin: auto;
    min-height: 70px;
    max-height: 130px;
    height: 10%;
    gap: 10px;

    background-color: ${BG};
    position: relative;
`;
const Row = styled.div`
    width: 80%;
    height: 100%;
    margin: auto;
    display: flex;
`;

const Space = styled.div<{ flexValue?: number, justifyContent?: string }>`
    flex: ${({ flexValue }) => (flexValue ? flexValue : 1)};
    display: flex;
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
    align-items: center;
    gap: 15px;
`;

const Logo = styled.img`
  width: 100px;

  @media( min-width: 768px ){
    width: 130px;
  }
`;

const Version = styled(P)`
    position: fixed;
    
    &.__bottom {
        bottom: 10px;
        right: 15px; 
    }

    &.__top {
        top: 10px;
        left: 15px;
        font-size: 13px;
    }
`;

const Header: React.FC<{ logoURL: string, simplified?: boolean }> = ({ logoURL, simplified=false }) => {

    return (
        <Container>
            <Row>
                <Space justifyContent='flex-start'>
                    {!simplified && <User />}
                    {!simplified && <Logout />}
                </Space> 
                <Space flexValue={3}>
                <Logo src={logoURL} alt='logo' />
                </Space>
                <Space justifyContent='flex-end' >
                    <LangSelect />
                    <Version className={simplified ? '__top' : '__bottom'}  style={{ color: 'white' }}>{VERSION_CODE}</Version>
                </Space>
            </Row>
            
        </Container>
    );
};

export default Header;
