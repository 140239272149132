import Axios from "axios";
import Authorization from './auth';
import { executeCall } from "./utils";
import ITimeEntries from "core/interfaces/ITimeEntries";

export interface ITimeEntriesAPI {
    options: {
        headers: unknown
    },
    get: (url: string, users: number[], date: Date) => Promise<ITimeEntries>
}

const crudder = (withAuth: boolean = true): ITimeEntriesAPI => {

    const headers = withAuth ? Authorization : {};

    return {
        options: {
            headers
        },
        get: (url, users, date) => executeCall(() => Axios.get<ITimeEntries>(url, { headers, params: { users: users.join(","), date: date.toISOString().split('T')[0] } })),
    };
};

export default crudder;