import { DEFAULT_FETCHING_STATE, DEFAULT_NO_ERROR } from 'state/constants';
import SurveyState from './state';
import ACTION_STRINGS from './actionStrings';
import { SurveyActions } from './actionTypes';

const INITIAL_STATE: SurveyState = {
    survey: null,
    metadata: null,
    fetching: { ...DEFAULT_FETCHING_STATE },
    error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action: SurveyActions ) => {
    switch (action.type) {
      case ACTION_STRINGS.FETCH_METADATA_START: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } } as SurveyState
      case ACTION_STRINGS.FETCH_METADATA_SUCCESS: return { ...state, metadata: action.payload, fetching: { ...DEFAULT_FETCHING_STATE } } as SurveyState

      case ACTION_STRINGS.FETCH_ERROR:
      case ACTION_STRINGS.FETCH_METADATA_ERROR: return { ...state, fetching: {...DEFAULT_FETCHING_STATE}, error: {timestamp: Date.now(), message: action.payload} } as SurveyState

      case ACTION_STRINGS.FETCH_START: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } } as SurveyState
      case ACTION_STRINGS.FETCH_SUCCESS: 
      return { ...state, survey: action.payload, fetching: { ...DEFAULT_FETCHING_STATE } } as SurveyState

      case ACTION_STRINGS.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

      default: return state;
  }
};

export default reducer;