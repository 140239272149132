import React from 'react';
import Icon from '@etiquette-ui/icons';

const Logout = () => {
    const logout = () => {
        localStorage.removeItem('auth');
        window.location.reload();
    };

    const auth = JSON.parse(localStorage.getItem('auth') ?? '{}');

    return Object.keys(auth).length > 0 ? (
        <div style={{ cursor: 'pointer' }} onClick={logout}>
            <Icon
                icon={'logout'}
                styles={{
                    svg: { width: '25px' },
                    line: {
                        fill: 'none',
                        strokeLinecap: 'round',
                        strokeLinejoin: 'round',
                        strokeWidth: '2px',
                        stroke: 'white',
                    },
                }}
            />
        </div>
    ) : null;
};

export default Logout;
