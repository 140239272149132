import React, { useEffect } from 'react';
import ILongInput from 'core/render/questions/LongInput';
import useI18n from 'i18n/useI18n';
import { TextArea } from '@etiquette-ui/inputs-dropdowns';
import QuestionComponent from '../interfaces/QuestionComponent';
import AnimateTransition from 'components/Animate';
import { useScroll } from 'providers/scroll';
import { cleanId } from 'core/utils';
import SectionTitle from 'components/SectionTitle';

const LongInput: React.FC<QuestionComponent<ILongInput, string>> = ({ id, question, dataAccess, nested }) => {
    const { translateObject } = useI18n();
    const [response, setResponse] = dataAccess();
    const { registerQuestion } = useScroll();

    useEffect(() => {
        registerQuestion(id, true);
        return () => {
            registerQuestion(id, false);
        };
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setResponse(event.target.value);

    return (
        <AnimateTransition id={cleanId(id)}>
            <div>
                <SectionTitle style={{ marginBottom: '1rem' }}>{translateObject(question.title)}</SectionTitle>
                <TextArea
                    inputProps={{
                        onChange: handleChange,
                        value: response,
                        placeholder: translateObject(question.placeholder),
                    }}
                    iconProps={{
                        icon: question.icon ?? 'comment',
                    }}
                />
            </div>
            {nested.map(({ component, condition }) => {
                if ((condition === '*' && response) || condition === response) {
                    return component;
                }
            })}
        </AnimateTransition>
    );
};

export default LongInput;
