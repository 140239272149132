import React, { useEffect } from 'react';
import Login from './pages/Login';
import Survey from './pages/Survey';
import { UUIDProvider } from 'providers/uuid';
import { useUUID } from 'providers/uuid';
import useSurvey from 'state/survey';
import CenterContainer from 'containers/CenterContainer';
import useI18n from 'i18n/useI18n';
import { H1 } from '@etiquette-ui/typography';
import { getToken } from 'resources/api/auth';

const Container = () => {
    const { translate } = useI18n();
    const uuid = useUUID();
    const { state, dispatcher } = useSurvey();

    useEffect(() => {
        dispatcher.fetchMetadataStart(uuid);
    }, [uuid]);

    return (
        <>
            {state.metadata === null ? (
                <CenterContainer>
                    <H1 style={{ color: 'white' }}>{translate('Loading...')}</H1>
                </CenterContainer>
            ) : !!getToken() || state.metadata.privacy === 'public' ? (
                <Survey />
            ) : (
                <Login />
            )}
        </>
    );
};

const Home = () => {
    return (
        <UUIDProvider>
            <Container />
        </UUIDProvider>
    );
};

export default Home;
