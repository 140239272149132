export enum PreSurveyStatus {
    LOADING = "LOADING",
    ERROR = "ERROR",
    VALID = "VALID",
}

export default interface PreSurveyComponent<T> {
    id: string;
    settings: T;
}
