import axios from "axios";
import { useEffect, useState } from "react";
import WaiterResponse from "../types/WaiterResponse";
import WaiterOption from "../types/WaiterOption";

const useWaiters = (url: string, serverUserTypes: string[]) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [waiters, setWaiters] = useState<WaiterOption[]>([]);
    useEffect(() => {
        if (!url) return;
        setLoading(true);
        axios
            .get<WaiterResponse[]>(`${url}${serverUserTypes.length > 0 ? `?types=${serverUserTypes.join(',')}` : ''}`)
            .then((res) => {
                const options = res.data.map((waiter: WaiterResponse) => ({
                    label: waiter.name,
                    value: waiter.id,
                }));

                setWaiters(options);
            })
            .catch((error) => {
                let errorMessage = 'Unknown error';
                if (axios.isAxiosError(error)) {
                    if (error.response && error.response.data && error.response.data) {
                        errorMessage = error.response.data.message;
                    } else {
                        errorMessage = error.message;
                    }
                }
                alert(errorMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [url]);
    return { loading, waiters };
};

export default useWaiters;
