import React, { useState } from 'react';
import styled from 'styled-components';
import langs from 'i18n/langs';
import useI18n from 'i18n/useI18n';
import Icon from '@etiquette-ui/icons';
import { TEXT, SECONDARY_DARK } from '@etiquette-ui/colors';

const Container = styled.div`
    position: relative;
    z-index: 5;
`;

const Toggle = styled.div`
    display: flex;
    height: 25px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    cursor: pointer;
`;

const Flag = styled.img`
    width: 25px;
    user-select: none;
`;

const Country = styled.span`
    font-size: 1rem;
    margin: 0;
    padding: 0;
    flex: 1;
    user-select: none;
    color: white;
    text-align: center;
`;

const LangList = styled.div`
    background-color: white;
    border-radius: 8px;
    position: absolute;
    top: 30px;
    right: 0;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-shadow: 0px 8px 24px #0332693D;
`;

const LangOption = styled.div<{ selected: boolean }>`
    display: flex;
    gap: 10px;
    cursor: pointer;
    padding: 5px;
    ${({ selected }) => (selected ? `background-color: ${SECONDARY_DARK} ;` : '')};
`;

const CountryOption = styled(Country)`
    color: ${TEXT};
`;

const LangSelect = () => {
    const { selectedLang, setSelectedLang } = useI18n();
    const [visible, setVisible] = useState(false);

    const changeLang = (lang: string) => {
      setVisible(false);
      setSelectedLang(lang);
    }

    return (
        <Container>
            <Toggle onClick={() => setVisible(!visible)}>
                <Flag src={langs[selectedLang].flag} alt="flag" />
                <Country>{selectedLang.toUpperCase()}</Country>
                <Icon
                    icon={visible ? 'chevron-up' : 'chevron-down'}
                    styles={{
                        svg: { width: '18px' },
                        line: {
                            fill: 'none',
                            strokeLinecap: 'round',
                            strokeLinejoin: 'round',
                            strokeWidth: '2px',
                            stroke: 'white',
                        },
                    }}
                />
            </Toggle>
            {visible && (
                <LangList>
                    {Object.keys(langs).map((key) => (
                        <LangOption key={key} selected={key === selectedLang} onClick={()=>changeLang(key)}>
                            <Flag src={langs[key].flag} alt={`flag-${key}`} />
                            <CountryOption>{langs[key].name}</CountryOption>
                        </LangOption>
                    ))}
                </LangList>
            )}
        </Container>
    );
};

export default LangSelect;
