import RenderNested from "core/classes/RenderNested";
import SingleChoiceComponent from "../components/SingleChoice";
import { SingleChoiceCondition } from "../questions/SingleChoice";

export default class SingleChoice extends RenderNested<SingleChoiceCondition> {

  getComponent() {
    return SingleChoiceComponent;
  }
  
}
