import React, { useEffect } from 'react';
import IStatement from 'core/render/questions/Statement';
import useI18n from 'i18n/useI18n';
import { BasicQuestionComponent } from '../interfaces/QuestionComponent';
import AnimateTransition from 'components/Animate';
import { useScroll } from 'providers/scroll';
import { cleanId } from 'core/utils';
import useGlobalVariables from 'state/globalVariables';

const Statement: React.FC<BasicQuestionComponent<IStatement>> = ({ id, question }) => {
    const { translateObject } = useI18n();
    const { registerQuestion } = useScroll();
    const { state: globalVariablesState } = useGlobalVariables();

    useEffect(() => {
        registerQuestion(id, true);
        return () => {
            registerQuestion(id, false);
        };
    }, []);

    return (
        <AnimateTransition id={cleanId(id)}>
            <div>
            {question.lines.map((line, index) => (
                <div key={question.name + '-' + -index} style={{ ...line.style }} dangerouslySetInnerHTML={{__html: translateObject(line.translations, globalVariablesState.variables)}} />
            ))}
            </div>
        </AnimateTransition>
    );
};

export default Statement;
