import { DEFAULT_FETCHING_STATE, DEFAULT_NO_ERROR } from 'state/constants';
import SettingsState from './state';
import ACTION_STRINGS from './actionStrings';
import { SettingsActions } from './actionTypes';
import { PreSurveyStatus } from 'core/presurvey/interfaces/PreSurveyComponent';

const INITIAL_STATE: SettingsState = {
    needsConfigPage: null,
    configCompleted: false,
    surveyCompleted: false,
    progress: 0,
    lang: '',
    configSectionsStatus: {},
    reference: null,
    fetching: { ...DEFAULT_FETCHING_STATE },
    error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action: SettingsActions ) => {
    switch (action.type) {
      case ACTION_STRINGS.SET_NEEDS_CONFIG_PAGE: return { ...state, needsConfigPage: action.payload, fetching: { ...DEFAULT_FETCHING_STATE } } as SettingsState
      case ACTION_STRINGS.SET_CONFIG_COMPLETED: return { ...state, configCompleted: action.payload, fetching: { ...DEFAULT_FETCHING_STATE } } as SettingsState
      case ACTION_STRINGS.SET_SURVEY_COMPLETED: return { ...state, surveyCompleted: action.payload, fetching: { ...DEFAULT_FETCHING_STATE } } as SettingsState
      case ACTION_STRINGS.SET_PROGRESS: return { ...state, progress: action.payload, fetching: { ...DEFAULT_FETCHING_STATE } } as SettingsState
      case ACTION_STRINGS.SET_CONFIG_SECTIONS_STATUS: {
        const { index, status } = action.payload as { index: number, status: PreSurveyStatus }
        const newStatus = {...state.configSectionsStatus};
        newStatus[index] = status;
        return { ...state, configSectionsStatus: newStatus, fetching: { ...DEFAULT_FETCHING_STATE } } as SettingsState
      }
      case ACTION_STRINGS.SET_SELECTED_LANG: return { ...state, lang: action.payload } as SettingsState

      case ACTION_STRINGS.SET_REFERENCE: {
        return { ...state, reference: action.payload } as SettingsState
      }

      case ACTION_STRINGS.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

      default: return state;
  }
};

export default reducer;