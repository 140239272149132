import Renderer from "core/classes/Renderer";
import StatementComponent from '../components/Statement';

export default class Statement extends Renderer {

  getComponent(){
    return StatementComponent
  }

}
