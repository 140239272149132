import React, { useEffect, useState } from 'react';
import IStaffRating, { StaffRatingCondition } from 'core/render/questions/StaffRating';
import useI18n from 'i18n/useI18n';
import { P, PSmall } from '@etiquette-ui/typography';
import { PRIMARY, SECONDARY } from '@etiquette-ui/colors';
import QuestionComponent from 'core/render/interfaces/QuestionComponent';
import { Stars } from '@etiquette-ui/misc';
import { cleanId, compliesWithRule, isDateInRange } from 'core/utils';
import AnimateTransition from 'components/Animate';
import { getUsersOfGroup, timeEntriesAPI } from 'resources/api';
import { useScroll } from 'providers/scroll';
import styled from 'styled-components';
import { ADMIN_IMAGES_URL } from 'resources/constants/urls';
import AllMembersImage from 'resources/img/all_members.png';
import DontRememberImage from 'resources/img/dont_remember.png';
import DefaultProfileImage from 'resources/img/default_profile.png';
import SectionTitle from 'components/SectionTitle';
import useSurvey from 'state/survey';
import { isAnswerEmpty } from 'core/utils/validation';
import RaitingContainer from 'containers/RaitingContainer';
import useGlobalVariables from 'state/globalVariables';
import { shuffleArray } from 'utils';
import { pathFinder } from 'utils/objectUtils';

interface User {
    id: number;
    name: string;
    secondName: string | null;
    isActive: number;
    userPosId: string | number | null;
    image: string | null;
    isCurrentlyCheckedIn: boolean
}

const UsersGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 15px;
`;

const Instruction = styled.p`
    font-size: 16px;
    text-align: center;
    margin-top: -10px;
    margin-bottom: -5px;
`;

const UserContainer = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
    gap: 10px;
    user-select: none;
    cursor: pointer;
    max-width: 50%;
    flex: 0 0 50%;

    figure, img,
    .iconContainer {
        border-radius: 50%;
        min-width: 51px;
        min-height: 51px;
    }

    figure{
        width: fit-content;
        margin: auto;
        max-width: 100%;
        overflow: hidden;
        transform:  ${({ active }) => (active ? 'scale(1.06)' : 'scale(1)')};
        transition-duration: .4s;
    }

    .iconContainer, img {
        border: solid 5px ${({ active }) => (active ? PRIMARY : 'transparent')};
    }

    .iconContainer {
        background: red;
    }

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        object-fit: cover;
    }

    p {
        text-align: center;
    }

    @media(  min-width: 350px){
        padding: 10px;
        max-width: 33.33333%;
        flex: 0 0 33.33333%;

        figure, img {
            min-width: 50px;
        }

        img {
            max-width: 54px;
        }
    }

    @media(  min-width: 451px){
        padding: 10px;
        max-width: 25%;
        flex: 0 0 25%;

        figure, img {
            min-width: 50px;
        }

        img {
            max-width: 54px;
        }
    }

    @media(  min-width: 525px){
        padding: 10px;
        max-width: 20%;
        flex: 0 0 20%;

        figure, img {
            min-width: 65px;
        }

        img {
            max-width: 64px;
        }
    }
`;

const CustomLabel = styled(PSmall)`
    width: fit-content;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 20px auto 15px auto;
    background-color: ${SECONDARY};
`;

const UserBox: React.FC<{ id: number; imageURL: string; name: string; active: boolean; onClick: () => void }> = ({
    id,
    imageURL,
    name,
    active,
    onClick,
}) => {
    return (
        <UserContainer active={active} onClick={onClick}>
            <figure>
                {imageURL && (<img src={imageURL} alt={`user_${id}`} />)}
            </figure>
            <div style={{ display: 'flex', alignItems: 'center', minHeight: '40px' }}>
                <P>{name}</P>
            </div>
        </UserContainer>
    );
};

const StaffRating: React.FC<QuestionComponent<IStaffRating, StaffRatingCondition>> = ({ id, question, dataAccess, nested }) => {
    const { translateObject, translate } = useI18n();
    const [state, setState] = dataAccess();
    const [users, setUsers] = useState([] as User[]);
    const { state: { survey } } = useSurvey();
    const { next, registerQuestion } = useScroll();
    const { dispatcher: globalVariablesDispatcher, state: globalVariablesState } = useGlobalVariables();

    useEffect(() => {
        registerQuestion(id, true);
        return () => {
            registerQuestion(id, false);
        };
    }, []);

    const insertSpecialAnswers = (users: User[]): User[] => {
        const width = window.innerWidth;
        let index = 0;
        if (users.length > 4) {
            if (width > 401) {
                index = 2;
            } else if (width > 350) {
                index = 1;
            }
        } else if (users.length > 0) {
            index = Math.floor(users.length / 2);
        }

        const additionalUsers: User[] = [];
        if (question.includeAllTeamMembers) {
            additionalUsers.push({
                name: 'All Team Members',
                image: AllMembersImage,
                id: -1,
                secondName: null,
                isActive: 1,
                userPosId: null,
                isCurrentlyCheckedIn: true,
            });
        }

        if (question.includeDontRemember) {
            additionalUsers.push({
                name: "Don't Remember",
                image: DontRememberImage,
                id: -2,
                secondName: null,
                isActive: 1,
                userPosId: null,
                isCurrentlyCheckedIn: true,
            });
        }

        const newUsers = [
            ...users.slice(0, index),
            ...additionalUsers,
            ...users.slice(index)
        ];

        return newUsers;
    };

    const maybeProcessTimeEntries = async (users: User[]) => {
        try {
            const { datetimeReference, timeEntriesURL } = question;
            if (!timeEntriesURL) return users;
            const referenceValue = datetimeReference && pathFinder(globalVariablesState.variables, datetimeReference)
            const dateReferenceValue = new Date(referenceValue ?? Date.now())
            const timeEntries = await timeEntriesAPI.get(timeEntriesURL, users.map(({ id }) => id), new Date(referenceValue ?? Date.now()));
            return users.filter((u: User) => {
                for (const entry of timeEntries[u.id + '']) {
                    const dateFrom = new Date(entry["check-in"]);
                    const dateTo = new Date(entry["check-out"]);
                    if (isDateInRange(dateReferenceValue, dateFrom, dateTo)) return true;
                }
                return false
            });
        } catch (error) {
            console.error(error)
            return users;
        }
    }

    useEffect(() => {
        if (!survey) return;
        (async () => {
            try {
                const users = await getUsersOfGroup({ groupId: survey.meta.groupId, division: question.division, role: question.role });
                const processedUsers = await maybeProcessTimeEntries(users);
                const shuffledUsers = shuffleArray(processedUsers);
                const usersWithSpecialAnswers = insertSpecialAnswers(shuffledUsers);
                setUsers(usersWithSpecialAnswers);
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    useEffect(() => {
        if (!!question.globalVariable) {
            const name = state.userId === -1 || state.userId === -2 ? "" : getUserName(state.userId);
            globalVariablesDispatcher.setGlobalVariables(question.globalVariable, name);
        }
        if (!isAnswerEmpty(state, question.type)) {
            next(cleanId(id));
        }
    }, [state.rating, state.userId]);

    const getUserName = (userId: number) => {
        const user = users.find(({ id }) => id === userId);
        return user ? user.name : '';
    }

    return (
        <AnimateTransition id={cleanId(id)}>
            <div>
                <SectionTitle style={{ textAlign: 'center' }}>{translateObject(question.title)}</SectionTitle>
                {question.instructions && (<Instruction>{translateObject(question.instructions)}</Instruction>)}
                <UsersGrid>
                    {users.map((user) => (
                        <UserBox
                            key={user.id}
                            onClick={() => {
                                setState({ ...state, userId: user.id });
                            }}
                            active={user.id === state.userId}
                            imageURL={!!user.image ? user.image : DefaultProfileImage}
                            id={user.id}
                            name={user.id < 0 ? translate(user.name) : user.name}
                        />
                    ))}
                </UsersGrid>
                <div>
                    <CustomLabel>
                        {state.userId === null
                            ? translate('Currently Not Rated')
                            : state.userId === -1 || state.userId === -2
                                ? translate('Rate your experience')
                                : translate('Rate your experience with', { name: getUserName(state.userId) })}
                    </CustomLabel>
                </div>
                <RaitingContainer>
                    <Stars
                        styles={{
                            svg: { width: '10px' },
                        }}
                        value={state.rating}
                        setValue={(value: number) => {
                            if (state.userId !== null) setState({ ...state, rating: value });
                        }}
                    />
                </RaitingContainer>
            </div>
            {nested.map(({ component, condition, id }, i) => {
                const { rule, value: expected } = condition;
                if (state.rating > 0 && compliesWithRule(rule, state.rating, expected)) {
                    return <React.Fragment key={`${i}-${id}`}>{component}</React.Fragment>;
                }
            })}
        </AnimateTransition>
    );
};

export default StaffRating;
