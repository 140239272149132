import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import I8nProvider from 'i18n/provider';
import AccessProvider from 'providers/access';
import AlertServiceProvider from '@etiquette-ui/alert-dialog';
import ErrorManagement from 'components/ErrorManagement';
import usePreSurvey from 'state/presurvey';
import useGlobalVariables from 'state/globalVariables';
import useResponse from 'state/response';
import useSettings from 'state/settings';
import useSurvey from 'state/survey';

const App = () => {
    const { state: preSurvey, dispatcher: preSurveyDispatcher } = usePreSurvey();
    const { state: globalVariables, dispatcher: globalVariablesDispatcher } = useGlobalVariables();
    const { state: response, dispatcher: responseDispatcher } = useResponse();
    const { state: settings, dispatcher: settingsDispatcher } = useSettings();
    const { state: survey, dispatcher: surveyDispatcher } = useSurvey();


    return (
    <AccessProvider>
        <I8nProvider>
            <AlertServiceProvider>
                <ErrorManagement
                    states={{
                        config: { state: preSurvey.error, resetError: preSurveyDispatcher.resetError },
                        data: { state: globalVariables.error, resetError: globalVariablesDispatcher.resetError },
                        response: { state: response.error, resetError: responseDispatcher.resetError },
                        settings: { state: settings.error, resetError: settingsDispatcher.resetError },
                        survey: { state: survey.error, resetError: surveyDispatcher.resetError },
                    }}
                />
                <Switch>
                    <Route path="/:uuid([a-fA-F0-9]{24})" component={Home} />
                    <Route component={NotFound} />
                </Switch>
            </AlertServiceProvider>
        </I8nProvider>
    </AccessProvider>
)};

export default App;
