import { ResetError } from 'state/interfaces/ResetError';
import ACTION_STRINGS from './actionStrings';
import { SetPreSurvey, ReplacePreSurvey, AddPreSurvey } from './actionTypes';
import PreSurveyType from 'core/presurvey/interfaces/PreSurveyType';

export const setPreSurveyAction = (key: string, value: PreSurveyType): SetPreSurvey => ({
    type: ACTION_STRINGS.SET_PRE_SURVEY,
    payload: { key, value },
});

export const replacePreSurveyAction = (payload: {[key: string]: PreSurveyType}): ReplacePreSurvey => ({
    type: ACTION_STRINGS.REPLACE_PRE_SURVEY,
    payload,
});

export const addPreSurveyAction = (payload: {[key: string]: PreSurveyType}): AddPreSurvey => ({
    type: ACTION_STRINGS.ADD_PRE_SURVEY,
    payload,
});

export const resetError = (): ResetError<ACTION_STRINGS> => ({
    type: ACTION_STRINGS.RESET_ERROR,
    payload: {}
});
