import HashTable from "core/interfaces/HashTable";
import IAnswer from "core/interfaces/IAnswer";
import IQuestion from "core/interfaces/IQuestion";
import ISurvey from "core/interfaces/ISurvey";
import { LongInputAnswer } from "core/render/questions/LongInput";
import { MultipleChoiceAnswer } from "core/render/questions/MultipleChoice";
import { OptionsInAnswer } from "core/render/questions/OptionsIn";
import { RatingAnswer } from "core/render/questions/Rating";
import { SimpleInputAnswer } from "core/render/questions/SimpleInput";
import { DateInputAnswer } from "core/render/questions/DateInput";
import { SingleChoiceAnswer } from "core/render/questions/SingleChoice";
import { StaffRatingAnswer } from "core/render/questions/StaffRating";
import { StaffOptionsAnswer } from "core/render/questions/StaffOptions";
import Topics, { TopicsAnswer } from 'core/render/questions/Topics';
import Form, { FormAnswer } from 'core/render/questions/Form';
import { getAnswerDefault, typeExists } from "core/plugins";

export const getAnswersObjFromSurvey = (survey: ISurvey): HashTable<IAnswer> => {
    return getAnswers(survey.questions);
};

const getAnswers = (questions: HashTable<IQuestion>) => {
    const answers = {} as HashTable<IAnswer>;

    for (const key of Object.keys(questions)) {
        const question = questions[key];
        const answerObj = getAnswerObject(question);
        if (answerObj === null) continue;
        if (question.nested !== undefined) {
            const nested = getAnswers(question.nested);
            if (Object.keys(nested).length > 0) answerObj.nested = nested;
        }
        answers[key] = answerObj;
    }

    return answers;
};

const getAnswerObject = (question: IQuestion) => {
    let ans = {} as IAnswer;

    if (typeExists(question.type)) {
        ans = {...getAnswerDefault(question.type)};
        return ans;
    }

    switch (question.type) {
        case 'simple-input':
            ans = { value: '', type: question.type } as SimpleInputAnswer;
            break;
        case 'long-input':
            ans = { value: '', type: question.type } as LongInputAnswer;
            break;
        case 'date-input':
            ans = { value: '', type: question.type } as DateInputAnswer;
            break;
        case 'rating':
            ans = { value: 0, type: question.type } as RatingAnswer;
            break;
        case 'options-in':
            ans = { value: '', type: question.type } as OptionsInAnswer;
            break;
        case 'multiple-choice':
            ans = { value: [] as string[], type: question.type } as MultipleChoiceAnswer;
            break;
        case 'single-choice':
            ans = { value: '', type: question.type } as SingleChoiceAnswer;
            break;
        case 'topics': {
            const value = {} as HashTable<string>;
            (question as Topics).topics.forEach((topic)=>{
                value[topic.id] = '';
            })
            ans = { value, type: question.type } as TopicsAnswer;
            break;
        }
        case 'staff-rating':
            ans = { value: { rating: 0, userId: null, }, type: question.type } as StaffRatingAnswer;
            break;
        case 'staff-options':
            ans = { value: { userId: null, }, type: question.type } as StaffOptionsAnswer;
            break;
        case 'form':
            const fields = (question as Form).blocks.reduce(
                (acc, curr) => [...acc, ...curr.fields.map((field) => ({ id: field.id, type: field.type }))],
                [] as HashTable<string>[]
            );
            const value: HashTable<any> = {};
            fields.forEach((field) => {
                switch (field.type) {
                    case 'checkbox':
                        value[field.id] = [];
                        break;
                    case 'phone':
                        value[field.id] = { countryCode: 'MX', phone: '' };
                        break;
                    default:
                        value[field.id] = '';
                }
            });
            ans = { value: { ...value }, type: question.type } as FormAnswer;
            break;
        case 'statement':
            return null;
        default:
            //console.error('getAnswerObject: type not found', question.type);
    }

    return ans;
};