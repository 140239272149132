import { Dispatch } from "react";
import { PreSurveyActions } from "./actionTypes";
import { setPreSurveyAction, replacePreSurveyAction, addPreSurveyAction, resetError } from "./actions";
import PreSurveyType from "core/presurvey/interfaces/PreSurveyType";

export default class ProductsDispatcher {
    _dispatch: Dispatch<PreSurveyActions>;

    constructor(dispatch: Dispatch<PreSurveyActions>) {
        this._dispatch = dispatch;
    }

    replacePreSurvey = (config: {[key: string]: PreSurveyType}) => this._dispatch(replacePreSurveyAction(config));

    addPreSurvey = (config: {[key: string]: PreSurveyType}) => this._dispatch(addPreSurveyAction(config));

    setPreSurvey = (key: string, value: PreSurveyType) => this._dispatch(setPreSurveyAction(key, value));

    resetError = () => this._dispatch(resetError());
}