import React from 'react';
import styled from 'styled-components';
import { ADMIN_IMAGES_URL } from 'resources/constants/urls';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;

    span {
        display: none;
    }

    @media( min-width: 768px ){
        span {
            display: flex;
        }
    }
`;

const User = () => {
    const auth = JSON.parse(localStorage.getItem('auth') ?? '{}');
    return Object.keys(auth).length > 0 ? (
        <Container>
            <Img style={{ maxHeight: '3rem' }} src={`${ADMIN_IMAGES_URL}${auth.image}`} alt={'user'} />
            <span style={{ margin: '10px' }}>{auth.name}</span>
        </Container>
    ) : null;
};

const Img = styled.img`
    max-height: 3rem;
    border-radius: 50%;
`;

export default User;
