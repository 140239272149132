import { Dispatch, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ResponseActions } from './actionTypes';
import Dispatcher from './dispatcher';
import selector from './selector';

const useResponse = () => {
    const dispatch = useDispatch<Dispatch<ResponseActions>>();
    const dispatcher = useMemo(()=>new Dispatcher(dispatch), [dispatch]);
    const state = useSelector(selector, shallowEqual);

    return { state, dispatcher };
}

export default useResponse;
