import React, { useEffect, useState } from 'react';
import ITopics, { TopicsCondition } from 'core/render/questions/Topics';
import QuestionComponent from 'core/render/interfaces/QuestionComponent';
import { H3 } from '@etiquette-ui/typography';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import { Tab } from '@etiquette-ui/selectors';
import { cleanId, compliesWithRule } from 'core/utils';
import { DISABLED_BUTTON } from '@etiquette-ui/colors';
import AnimateTransition from 'components/Animate';
import { useScroll } from 'providers/scroll';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import SectionTitle from 'components/SectionTitle';
import useGlobalVariables from 'state/globalVariables';

const TopicContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 1rem 1.5rem;
    flex-shrink: 1;
    flex-grow: 1;
    background-color: white;
`;

interface ITopicsWrapper {
    alignment: string;
}

const TopicsWrapper = styled.div<ITopicsWrapper>`
    display: flex;
    flex-direction: row;
    justify-content: ${({ alignment }) => alignment};
    gap: 2px;
    overflow: scroll;
    background-color: ${DISABLED_BUTTON};
    ::-webkit-scrollbar {
        display: none;
    }
`;

const CustomTab = styled(Tab)`
    button {
        width: max-content;
    }
`;

const align: (alignment: string) => string = (alignment) => {
    switch (alignment) {
        case 'left':
            return 'flex-start';
        case 'right':
            return 'flex-end';
        case 'center':
            return 'space-between';
        default:
            return alignment;
    }
};

const Topics: React.FC<QuestionComponent<ITopics, TopicsCondition>> = ({ id, question, dataAccess, nested }) => {
    const { translateObject } = useI18n();
    const [state, setState] = dataAccess();
    const { state: globalVariablesState } = useGlobalVariables();

    const [values, setValues] = useState<{ [key: string]: number | null }>({});
    const { next, registerQuestion } = useScroll();

    useEffect(() => {
        registerQuestion(id, true);
        return () => {
            registerQuestion(id, false);
        };
    }, []);

    useEffect(() => {
        let optionValues = {};
        question.topics.forEach((topic) => {
            topic.options.forEach((option) => {
                optionValues = { ...optionValues, [option.id]: option.value };
            });
        });

        setValues(optionValues);
    }, [question.topics]);

    const setTab = (topicId: string, optionId: string) => {
        setState({ ...state, [topicId]: optionId });

            const topic = question.topics.find((t)=>t.id === topicId);
            if(topic === undefined) return;
            const index = question.topics.indexOf(topic);
            if(index === -1) return;
            if(index === question.topics.length -1 ) {
                next(cleanId(id));
                return;
            }
            const element = document.getElementById(question.topics[index+1].id);
            if (!!element)
                smoothScrollIntoView(element, {
                    scrollMode: 'always',
                    behavior: 'smooth',
                    inline: 'center',
                    block: 'center',
                });
             
    };

    const isTabSelected = (topicId: string, optionId: string) => state[topicId] === optionId;

    const getValue: (topicId: string) => number | null = (topicId) => {
        if (state[topicId] === undefined) {
            return null;
        }

        const optionId = state[topicId];
        return values[optionId] === undefined ? null : values[optionId];
    };

    const compliesWithTopicRules: (condition: TopicsCondition) => boolean = (condition) => {
        const { rule, conditions } = condition;
        const listConditions = Object.entries(conditions);

        const complies: (id: string, rule: string, value: string | number | null) => boolean = (id, rule, value) => {
            const got = rule === 'id' ? state[id] || null : getValue(id);
            return compliesWithRule(rule, got, value);
        };

        if (rule === 'and') {
            return listConditions.every(([id, { rule, value }]) => complies(id, rule, value));
        }

        return listConditions.some(([id, { rule, value }]) => complies(id, rule, value));
    };

    return (
        <AnimateTransition id={cleanId(id)}>
            <div>
            {question.title !== null && <SectionTitle style={{ margin: '1rem 0rem' }} dangerouslySetInnerHTML={{__html: translateObject(question.title, globalVariablesState.variables)}}/>}
            <TopicsWrapper alignment={align(question.alignment)}>
                {question.topics.map((topic) => (
                    <div key={topic.id} style={{ display: 'flex', width: '100%' }} >
                        <TopicContainer id={topic.id}>
                            <H3 style={{ whiteSpace: 'nowrap', fontSize: '1.23rem', fontWeight: '500' }}>{translateObject(topic.title)}</H3>
                            {topic.options.map((option) => (
                                <CustomTab
                                    key={option.id}
                                    selected={isTabSelected(topic.id, option.id)}
                                    onClick={() => setTab(topic.id, option.id)}
                                    type="button"
                                >
                                    {translateObject(option.translations)}
                                </CustomTab>
                            ))}
                        </TopicContainer>
                    </div>
                ))}
            </TopicsWrapper>
            </div>
            {nested.map(({ component, condition, id }, i) => {
                if (compliesWithTopicRules(condition)) {
                    return <React.Fragment key={`${i}-${id}`}>{component}</React.Fragment>;
                }
                return null;
            })}
        </AnimateTransition>
    );
};
export default Topics;
