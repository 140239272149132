import { FC } from "react";
import PreSurveyComponent from "core/presurvey/interfaces/PreSurveyComponent";
import configDictionary from "../plugins/config.dictionary";
import HashTable from "core/interfaces/HashTable";
import EmployeeSelect from "./components/EmployeeSelect";
import SimpleInput from "./components/SimpleInput";

const configFactory: (type: string)=>FC<PreSurveyComponent<unknown>> = (type) => {
    switch(type) {
      case 'simple-input': {
        return SimpleInput;
      }
      case 'employee': {
        return EmployeeSelect;
      }
      default:
        const plugin = (configDictionary as HashTable<any>)[type];
        if(!!plugin) {
          return plugin;
        } else {
          console.error('Config type not found:', type);
        }
    }
};

export default configFactory;
