import { addValueToObject, pathFinder } from "utils/objectUtils";
import Datable from "../interfaces/Datable";

export default class ContextDataAccess implements Datable{

    protected data: Object;
    protected modifyData: (d: any) => void;

    constructor(data: object, modifyData: (d: any)=>void) {
        this.data = data;
        this.modifyData = modifyData;
    }

    updateState(address: string): (d: any)=>void {
        return (d: any) => {
            const newData = {...this.data};
            addValueToObject(newData, address, d);
            this.modifyData(newData);
        };
    }

    getDataAccessor(address: string): [object, (d: any) => void] {
        const part = pathFinder(this.data, address);

        return [part, this.updateState(address)];
    }
}