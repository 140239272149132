import Base from "core/classes/BaseFactory";
import IQuestion from "core/interfaces/IQuestion";

import ISimpleInput from "core/render/questions/SimpleInput";
import IStatement from "core/render/questions/Statement";
import IRating from "core/render/questions/Rating";
import IOptionsIn from "core/render/questions/OptionsIn";
import IMultipleChoice from "core/render/questions/MultipleChoice";
import ISingleChoice from "core/render/questions/SingleChoice";
import ITopics from "core/render/questions/Topics";
import IForm from "core/render/questions/Form";
import ILongInput from "core/render/questions/LongInput";
import IStaffRating from "core/render/questions/StaffRating";
import IStaffOptions from "core/render/questions/StaffOptions";
import IDateInput from "core/render/questions/DateInput";

import DateInput from "./classes/DateInput";
import SimpleInput from "./classes/SimpleInput";
import Statement from "./classes/Statement";
import Rating from "./classes/Rating";
import MultipleChoice from "./classes/MultipleChoice";
import SingleChoice from "./classes/SingleChoice";
import OptionsIn from "./classes/OptionsIn";
import Topics from "./classes/Topics";
import Form from "./classes/Form";
import LongInput from "./classes/LongInput";
import StaffRating from "./classes/StaffRating";
import StaffOptions from "./classes/StaffOptions";

import { typeExists, getQuestion } from "core/plugins";
import { PluginsList } from "core/plugins/questions.dictionary";

export default class PreviewFactory extends Base {
  public createQuestion(question: IQuestion, id: string): any {

    if (typeExists(question.type)) {
      const questionType = question.type as keyof PluginsList;
      return getQuestion(questionType, question, id, this);
    }


    switch (question.type) {
      case "statement":
        return new Statement(question as IStatement, id, this);
      case "simple-input":
        return new SimpleInput(question as ISimpleInput, id, this);
      case "date-input":
        return new DateInput(question as IDateInput, id, this);
      case "rating":
        return new Rating(question as IRating, id, this);
      case "options-in":
        return new OptionsIn(question as IOptionsIn, id, this);
      case "multiple-choice":
        return new MultipleChoice(question as IMultipleChoice, id, this);
      case "single-choice":
        return new SingleChoice(question as ISingleChoice, id, this);
      case "topics":
        return new Topics(question as ITopics, id, this);
      case "form":
        return new Form(question as IForm, id, this);
      case "long-input":
        return new LongInput(question as ILongInput, id, this);
      case "staff-rating":
        return new StaffRating(question as IStaffRating, id, this);
      case "staff-options":
        return new StaffOptions(question as IStaffOptions, id, this);
      default:
        throw new Error("Unknown question type: " + question.type);
    }
  }
}
