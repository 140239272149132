import PreSurveyConditionEvalCallback from "../interfaces/PreSurveyConditionEvalCallback";

export type SimpleInputConditionRule = string;

const evalSimpleInput: PreSurveyConditionEvalCallback<SimpleInputConditionRule> = (condition, key, preSurveySections) => {
    const variable = preSurveySections[key];
    if(!variable || variable.type !== 'simple-input') return true;

    if ((condition === '*' && variable) || condition === variable.value) {
        return true;
    }
    return false;
}

export default evalSimpleInput;