import HashTable from 'core/interfaces/HashTable';
import React, { useCallback } from 'react';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import useResponse from 'state/response';
import useSurvey from 'state/survey';

const orderedValidQuestions: (surveyFlat: { [key: string]: any }) => string[] = (surveyFlat) => {
    let list = [] as string[];

    Object.keys(surveyFlat)
        .sort((a, b) => {
            return surveyFlat[a].position - surveyFlat[b].position;
        })
        .forEach((key) => {
            list.push(key);
            if (!!surveyFlat[key].nested) {
                const tmp = orderedValidQuestions(surveyFlat[key].nested);
                list = [...list, ...tmp];
            }
        });

    return list;
};

export type ScrollContextType = {
    next: (id: string) => void;
    registerQuestion: (key: string, exists: boolean) => void;
};

const ScrollContext = React.createContext<ScrollContextType>({
    next: (id: string) => {},
    registerQuestion: (key: string, exists: boolean) => {},
});

export const useScroll = () => React.useContext(ScrollContext);

const renderedQuestions = {} as HashTable<any>;

const registerQuestion = (key: string, exists: boolean) => {
    renderedQuestions[key.split('.')[key.split('.').length-1]] = exists;
}

export const ScrollProvider: React.FC<{ children: any }> = ({ children }) => {
    const { state: { survey } } = useSurvey();
    const { state: { answers: response } } = useResponse();

    const next = useCallback((id: string) => {
        setTimeout(() => {
            if (survey === null || response === null || renderedQuestions === null) return;
            const list = orderedValidQuestions(survey.questions);

            let index = list.indexOf(id);
            if (index === -1) return;

            index += 1;
            let found = false;
            while (!found && index < list.length) {
                if (renderedQuestions[list[index]]) {
                    found = true;
                    const element = document.getElementById(list[index]);
                    if (element)
                        smoothScrollIntoView(element, {
                            scrollMode: 'always',
                            behavior: 'smooth',
                            inline: 'center',
                            block: 'center',
                        });
                }
                index++;
            }
        }, 500);
    }, [response, survey]);

    return <ScrollContext.Provider value={{ next, registerQuestion }}>{children}</ScrollContext.Provider>;
};
