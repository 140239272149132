import React from 'react';
import { useParams } from 'react-router';

type UUIDParams = {
    uuid: string;
};

const UUIDContext = React.createContext<string>('');

export const useUUID = () => React.useContext(UUIDContext);

export const UUIDProvider: React.FC<{ children: any }> = ({ children }) => {
    const { uuid } = useParams<UUIDParams>();

    return <UUIDContext.Provider value={uuid}>{children}</UUIDContext.Provider>;
};
