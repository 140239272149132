import React from "react";
import { Dropdown } from "@etiquette-ui/inputs-dropdowns";
import styled from "styled-components";
import useI18n from "i18n/useI18n";
import { waiterLabel, waiterPlaceholder } from "./translations";
import WaiterOption from "./types/WaiterOption";
import useUpdateAnswer from "./data/useUpdateAnswer";
import { useData } from "./data";

const CustomDropdown = styled(Dropdown)`
    .selector__menu-list {
        max-height: 170px;
    }
`;

const ServerDropdown: React.FC<{
    id: string;
    section: any;
}> = ({ id, section }) => {
    const { updateWaiter } = useUpdateAnswer(id, section);
    const { translateObject } = useI18n();
    const { waiters } = useData();

    return (
        <CustomDropdown
            labelProps={{
                label: translateObject(waiterLabel),
            }}
            selectProps={{
                onChange: (e: WaiterOption) =>
                    updateWaiter({
                        value: e.value,
                        label: e.label,
                    }),
                options: waiters,
                placeholder: translateObject(waiterPlaceholder),
                value: section?.waiter
                    ? {
                          label: section.waiter.label,
                          value: section.waiter.value,
                      }
                    : null,
            }}
        />
    );
};

export default ServerDropdown;
