import ISurvey, { Metadata } from 'core/interfaces/ISurvey';
import { checkNeedsConfigPage } from 'core/utils';
import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { surveyAPI } from 'resources/api';
import { setNeedsConfigPageAction } from 'state/settings/actions';
import { AppState } from 'state/store';
import { processError } from 'state/utils';
import { fetchErrorAction, fetchMetadataErrorAction, fetchMetadataSuccessAction, fetchSuccessAction } from './actions';
import ACTION_STRINGS from "./actionStrings";
import { FetchMetadataStart } from './actionTypes';

function* fetchMetadataStartAsync({payload}: FetchMetadataStart): Generator<unknown, void, Metadata> {
  try {
    const res = yield surveyAPI.metadata(payload);
    yield put(fetchMetadataSuccessAction(res));
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put(fetchMetadataErrorAction(message));
  }
}

function* fetchMetadataStart() {
  yield takeLatest(ACTION_STRINGS.FETCH_METADATA_START, fetchMetadataStartAsync);
}

function* fetchStartAsync(): Generator<unknown, void, ISurvey | Metadata> {
  try {
    const metadata = (yield select((state: AppState) => state.survey.metadata)) as Metadata;

    const fetcher = metadata.privacy === 'public' ? surveyAPI.getPublic : surveyAPI.get
    const res = (yield fetcher(metadata._id)) as ISurvey;

    yield put(fetchSuccessAction(res));
    yield put(setNeedsConfigPageAction(checkNeedsConfigPage(res)));
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put(fetchErrorAction(message));
  }
}

function* fetchStart() {
  yield takeLatest(ACTION_STRINGS.FETCH_START, fetchStartAsync);
}

export default function* sagas() {
  yield all([call(fetchMetadataStart),call(fetchStart)]);
}
