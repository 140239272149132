import React, { useMemo } from 'react';
import Layout from 'containers/Layout';
import Header from 'components/Header';
import SurveyContainer from 'containers/SurveyContainer';
import Overflow from 'containers/Overflow';
import styled from 'styled-components';
import PrimaryButton from '@etiquette-ui/buttons';
import useI18n from 'i18n/useI18n';
import { PreSurveyStatus } from 'core/presurvey/interfaces/PreSurveyComponent';
import Config from 'core/presurvey';
import { Spinner } from '@etiquette-ui/misc';
import { DISABLED_BUTTON } from '@etiquette-ui/colors';
import useSettings from 'state/settings';
import ISurvey from 'core/interfaces/ISurvey';
import useResponse from 'state/response';
import { P } from '@etiquette-ui/typography';
import useWindowSize from 'hooks/useWindowSize';

const SurveyBackground = styled.div`
    gap: 15px;
    width: 90%;
    max-width: 450px;
    height: 100%;
    padding: 20px 15px;
    border-radius: 24px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    overflow-y: scroll;
    background-color: white;

    /* Hide scrollbar properties */
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: 768px) {
        width: 80%;
        max-width: 80%;
        gap: 25px;
        padding: 50px 30px;
    }

    @media (min-width: 451px) and (max-width: 768px) {
        padding: 30px 35px;
        padding-top: 45px;
        gap: 25px;
    }
`;

const CustomSurveyContainer = styled(SurveyContainer)`
    margin-top: 0px;
    height: 86%;

    @media (min-width: 768px) {
        height: fit-content;
        min-height: auto;
        max-height: 83%;
    }
`;

const Title = styled(P)`
    text-align: left;
    font-size: 19px;
    width: 100%;
    max-width: 500px;
    line-height: 30px;

    @media (min-width: 768px) {
        width: 70%;
    }
`;

const Button = styled(PrimaryButton)`
    width: 75%;
    max-width: 350px;
    height: unset;
    color: white;
    button {
        height: unset;
        margin: unset;
        padding: 15px 0;
        display: flex;
        justify-content: center;
        color: white;
    }
`;

const PreSurvey: React.FC<{ survey: ISurvey }> = ({ survey }) => {
    const config = useMemo(()=>new Config(survey.presurvey), [survey]);
    const { translate } = useI18n();
    const { state: settingsState, dispatcher: settingsDispatcher } = useSettings();
    const { dispatcher: responseDispatcher } = useResponse();
    const [, windowHeight] = useWindowSize();

    const status = useMemo<PreSurveyStatus>(()=>{
        if (Object.values(settingsState.configSectionsStatus).some((s) => s === PreSurveyStatus.LOADING)) {
            return PreSurveyStatus.LOADING;
        }
        if (Object.values(settingsState.configSectionsStatus).some((s) => s === PreSurveyStatus.ERROR)) {
            return PreSurveyStatus.ERROR;
        }
        return PreSurveyStatus.VALID;
    }, [settingsState.configSectionsStatus]);

    const submit = (e: any) => {
        e.preventDefault();
        if (status !== PreSurveyStatus.VALID) return;
        settingsDispatcher.setConfigCompleted(true);
        responseDispatcher.deliverStart();
    };

    const sections = useMemo(()=>config.sections(),[config]);

    return (
        <Layout height={windowHeight}>
            <Header logoURL={survey.style.logo ?? ''} />
            <CustomSurveyContainer onSubmit={submit}>
                <Overflow>
                    <SurveyBackground>
                        <Title>{translate('Check data below and start survey when ready')}</Title>
                        {sections.map((Section) => {
                            const Component = Section.component;
                            return (
                                <Component
                                    key={Section.key}
                                    id={Section.key}
                                    settings={Section.settings}
                                />
                            );
                        })}
                        <Button disabled={status !== PreSurveyStatus.VALID}>
                            {status !== PreSurveyStatus.LOADING ? (
                                translate('Start Survey')
                            ) : (
                                <Spinner
                                    style={{
                                        '--color': 'white',
                                        '--bg': DISABLED_BUTTON,
                                        marginRight: '10px',
                                    }}
                                />
                            )}
                        </Button>
                    </SurveyBackground>
                </Overflow>
            </CustomSurveyContainer>
        </Layout>
    );
};

export default PreSurvey;
