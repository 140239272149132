enum ACTION_STRINGS {
    FETCH_METADATA_START = "[SURVEY] FETCH METADATA START",
    FETCH_METADATA_SUCCESS = "[SURVEY] FETCH METADATA SUCCESS",
    FETCH_METADATA_ERROR = "[SURVEY] FETCH METADATA ERROR",

    FETCH_START = "[SURVEY] FETCH START",
    FETCH_SUCCESS = "[SURVEY] FETCH SUCCESS",
    FETCH_ERROR = "[SURVEY] FETCH ERROR",

    RESET_ERROR = "[SURVEY] RESET ERROR",
}

export default ACTION_STRINGS;