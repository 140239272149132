import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { history } from "./resources/history";
import store from 'state/store';

ReactDOM.render(
    <React.Fragment>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>
    </React.Fragment>,
    document.getElementById('root')
);
