import ISurvey, { Metadata } from 'core/interfaces/ISurvey';
import { ResetError } from 'state/interfaces/ResetError';
import ACTION_STRINGS from './actionStrings';
import { FetchError, FetchMetadataStart, FetchMetadataSuccess, FetchStart, FetchSuccess } from './actionTypes';

export const fetchMetadataStartAction = (payload: string): FetchMetadataStart => ({
    type: ACTION_STRINGS.FETCH_METADATA_START,
    payload,
});

export const fetchMetadataSuccessAction = (payload: Metadata): FetchMetadataSuccess => ({
    type: ACTION_STRINGS.FETCH_METADATA_SUCCESS,
    payload,
});

export const fetchMetadataErrorAction = (payload: any): FetchError => ({
    type: ACTION_STRINGS.FETCH_METADATA_ERROR,
    payload,
});

export const fetchStartAction = (): FetchStart => ({
    type: ACTION_STRINGS.FETCH_START,
    payload: null,
});

export const fetchSuccessAction = (payload: ISurvey): FetchSuccess => ({
    type: ACTION_STRINGS.FETCH_SUCCESS,
    payload,
});

export const fetchErrorAction = (payload: any): FetchError => ({
    type: ACTION_STRINGS.FETCH_ERROR,
    payload,
});

export const resetError = (): ResetError<ACTION_STRINGS> => ({
    type: ACTION_STRINGS.RESET_ERROR,
    payload: {}
});