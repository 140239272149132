import evalConditionDictionary from "../plugins/evalCondition.dictionary";
import PreSurveyConditionEvalCallback from "./interfaces/PreSurveyConditionEvalCallback";
import evalSimpleInput from "./conditionEvalCallbacks/evalSimpleInput";
import evalEmployeeSelect from "./conditionEvalCallbacks/evalEmployeeSelect";
import PreSurveyConditionRule from "./interfaces/PreSurveyConditionRule";
import PreSurveyCondtionConfig from "./interfaces/PreSurveyConditionConfig";
import HashTable from "core/interfaces/HashTable";
import PreSurveyType from "./interfaces/PreSurveyType";

const defaultEval: PreSurveyConditionEvalCallback<PreSurveyConditionRule> = (condition, key, preSurveySections) => {
  return true;
}

const preSurveyConditionEvalCallbackFactory: (conditionConfig: PreSurveyCondtionConfig, preSurveySections: HashTable<PreSurveyType>)=> Boolean = (conditionConfig, preSurveySections) => { //TODO: Generate type with posible condition types
    switch(conditionConfig.type) {
      case 'simple-input': {
        return evalSimpleInput(conditionConfig.condition, conditionConfig.key, preSurveySections);
      }
      case 'employee-select': {
        return evalEmployeeSelect(conditionConfig.condition, conditionConfig.key, preSurveySections);
      }
      default:
        const plugin = evalConditionDictionary[conditionConfig.type];
        if(!!plugin) {
          return plugin(conditionConfig.condition, conditionConfig.key, preSurveySections);
        } else {
          console.error('Config type not found:', conditionConfig.type);
          return defaultEval(conditionConfig.condition, conditionConfig.key, preSurveySections);
        }
    }
};

export default preSurveyConditionEvalCallbackFactory;
