import CenterContainer from 'containers/CenterContainer';
import { H1 } from '@etiquette-ui/typography';
import React, { useEffect } from 'react';
import useSurvey from 'state/survey';
import Complete from './pages/Complete';
import Main from './pages/Main';
import PreSurvey from './pages/PreSurvey';
import useI18n from 'i18n/useI18n';
import useSettings from 'state/settings';
import useResponse from 'state/response';
import { getAnswersObjFromSurvey } from 'core/utils/response';
import validateSurveyAnswers from 'core/utils/validation';
import { checkSurveyProgress } from 'core/utils';
import useGlobalVariables from 'state/globalVariables';
import usePreSurvey from 'state/presurvey';
import useQuery from 'hooks/useQuery';

const SurveyLayout: React.FC = () => {
    const { translate } = useI18n();
    const { dispatcher, state } = useSurvey();
    const { state: settingsState, dispatcher: settingsDispatcher } = useSettings();
    const { state: { answers, answersHash }, dispatcher: responseDispatcher } = useResponse();
    const { dispatcher: globalVariablesDispatcher  } = useGlobalVariables();
    const { dispatcher: preSurveyDispatcher } = usePreSurvey();
    // const query = useQuery();

    // useEffect(() => {
    //     if (Array.from(query.keys()).length > 0) preSurveyDispatcher.addPreSurvey(Object.fromEntries(query.entries()));
    // }, [preSurveyDispatcher, query]);

    useEffect(() => {
        dispatcher.fetchStart();
    }, [dispatcher]);

    useEffect(()=>{
        if(state.survey !== null) {
            const answers = getAnswersObjFromSurvey(state.survey);
            responseDispatcher.replaceResponse(answers);
            globalVariablesDispatcher.addGlobalVariables(state.survey.globalVariables);
        }
    }, [responseDispatcher, globalVariablesDispatcher, state.survey]);

    useEffect(()=>{
        settingsDispatcher.setProgress(checkSurveyProgress(validateSurveyAnswers(answers)));
    }, [settingsDispatcher, answers]);

    useEffect(()=>{
        responseDispatcher.createValidationTree(answersHash);
    }, [responseDispatcher, answersHash]);

    return (
        <>
            {state.survey === null || settingsState.needsConfigPage === null || answers===null ? (
                <CenterContainer>
                    <H1 style={{ color: 'white' }}>{translate('Loading...')}</H1>
                </CenterContainer>
            ) : settingsState.surveyCompleted ? (
                <Complete />
            ) : (settingsState.needsConfigPage && !settingsState.configCompleted) ? (
                <PreSurvey survey={state.survey} />
            ) : (
                <Main survey={state.survey} answers={answers} />
            )}
        </>
    );
};

export default SurveyLayout;
