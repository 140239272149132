import { Dispatch } from "react";
import { SettingsActions } from "./actionTypes";
import { setNeedsConfigPageAction, setConfigCompletedAction, setProgressAction, setConfigSectionsStatusAction, setSurveyCompletedAction, resetError, setSelectedLang, setReferenceAction } from "./actions";
import { PreSurveyStatus } from "core/presurvey/interfaces/PreSurveyComponent";

export default class ProductsDispatcher {
    _dispatch: Dispatch<SettingsActions>;

    constructor(dispatch: Dispatch<SettingsActions>) {
        this._dispatch = dispatch;
    }

    setNeedsConfigPage = (needsConfigPage: boolean) => this._dispatch(setNeedsConfigPageAction(needsConfigPage));

    setConfigCompleted = (configCompleted: boolean) => this._dispatch(setConfigCompletedAction(configCompleted));
    

    setProgress = (progress: number) => this._dispatch(setProgressAction(progress));

    setConfigSectionsStatus = (index: string, status: PreSurveyStatus) => this._dispatch(setConfigSectionsStatusAction(index, status));
    
    setSurveyCompleted = (surveyCompleted: boolean) => this._dispatch(setSurveyCompletedAction(surveyCompleted));

    setSelectedLang = (lang: string) => this._dispatch(setSelectedLang(lang));

    setReference = (value: string | null) => this._dispatch(setReferenceAction(value));

    resetError = () => this._dispatch(resetError());
}