import React, { useEffect } from 'react';
import IRating, {RatingCondition} from 'core/render/questions/Rating';
import useI18n from 'i18n/useI18n';
import QuestionComponent from 'core/render/interfaces/QuestionComponent';
import {Stars} from "@etiquette-ui/misc";
import {cleanId, compliesWithRule} from "core/utils";
import AnimateTransition from 'components/Animate';
import { useScroll } from 'providers/scroll';
import SectionTitle from 'components/SectionTitle';
import { isAnswerEmpty } from 'core/utils/validation';
import RaitingContainer from 'containers/RaitingContainer';


const Rating: React.FC<QuestionComponent<IRating, RatingCondition>> = ({id, question, dataAccess, nested}) => {
    const {translateObject} = useI18n();
    const [state, setState] = dataAccess();
    const { next, registerQuestion } = useScroll();
    useEffect(()=>{
        registerQuestion(id, true);
        return () => {
            registerQuestion(id, false);
        }
    }, []);

    useEffect(()=>{
        if(!isAnswerEmpty(state, question.type)){
            next(cleanId(id));
        }
    }, [state]);

    return (
        <AnimateTransition id={cleanId(id)}>
            <div>
            <SectionTitle style={{textAlign: 'center'}}>{translateObject(question.title)}</SectionTitle>
            <RaitingContainer>
                <Stars styles={{
                    svg: {width: '10px'},
                }} value={state} setValue={setState}/>
            </RaitingContainer>
            </div>
            {nested.map(({component, condition, id}, i) => {
                const {rule, value: expected} = condition;
                if (state > 0 && compliesWithRule(rule, state, expected)) {
                    return <React.Fragment key={`${i}-${id}`}>{component}</React.Fragment>;
                }
            })}
        </AnimateTransition>
    );
};

export default Rating;
