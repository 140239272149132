import { all, call } from 'redux-saga/effects';
import survey from './survey/sagas';
import response from './response/sagas';

export default function* rootSaga() {
  yield all([
    call(survey),
    call(response),
  ]);
}
