import React, { useEffect } from "react";
import ISingleChoice, { SingleChoiceCondition } from "core/render/questions/SingleChoice";
import useI18n from "i18n/useI18n";
import { Tab } from "@etiquette-ui/selectors";
import styled from "styled-components";
import QuestionComponent from "core/render/interfaces/QuestionComponent";
import Option from "core/render/interfaces/Option";
import { cleanId, compliesWithRule } from "core/utils";
import AnimateTransition from "components/Animate";
import { useScroll } from "providers/scroll";
import SectionTitle from "components/SectionTitle";
import { isAnswerEmpty } from "core/utils/validation";
import useGlobalVariables from "state/globalVariables";

const OptionsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 15px 0;
`;

const CustomTab = styled(Tab)`
    button {
        height: 38px;
        padding: 0 15px;
        font-weight: 500;
    }
`;

const fulfilsCondition: (
    question: ISingleChoice,
    response: any,
    condition: SingleChoiceCondition
) => boolean = (question, response, condition) => {
    const resValue =
        condition.rule === "id" || condition.rule === "nid"
            ? response
            : question.options.find((opt) => opt.id === response)?.value;
    const { rule, value: expected } = condition;
    return compliesWithRule(rule, resValue, expected);
};

const MultipleChoice: React.FC<QuestionComponent<ISingleChoice, SingleChoiceCondition>> = ({
    id,
    question,
    dataAccess,
    nested,
}) => {
    const { translateObject } = useI18n();
    const [response, setState] = dataAccess();
    const { next, registerQuestion } = useScroll();
    const { dispatcher: globalVariablesDispatcher } = useGlobalVariables();

    useEffect(() => {
        registerQuestion(id, true);
        return () => {
            registerQuestion(id, false);
        };
    }, []);

    useEffect(() => {
        if (!isAnswerEmpty(response, question.type)) {
            next(cleanId(id));
        }
    }, [response]);

    const onTabSelected = (id: string) => {
        if (!!question.globalVariable && !!id) {
            const divisionItem = question.options.find((option: Option) => option.id === id);
            if (!!divisionItem)
                globalVariablesDispatcher.setGlobalVariables(
                    question.globalVariable,
                    divisionItem.value
                );
        }

        setState(id);
    };

    return (
        <AnimateTransition id={cleanId(id)}>
            <div>
                <SectionTitle style={{ textAlign: "center" }}>
                    {translateObject(question.title)}
                </SectionTitle>
                <OptionsContainer>
                    {question.options.map((option) => (
                        <CustomTab
                            key={option.id}
                            selected={response === option.id}
                            onClick={() => onTabSelected(option.id)}
                            type="button"
                        >
                            {translateObject(option.translations)}
                        </CustomTab>
                    ))}
                </OptionsContainer>
            </div>
            {nested.map(({ component, condition }) => {
                if (fulfilsCondition(question, response, condition)) {
                    return component;
                }
                return null;
            })}
        </AnimateTransition>
    );
};

export default MultipleChoice;
