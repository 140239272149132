import { DEFAULT_FETCHING_STATE, DEFAULT_NO_ERROR } from 'state/constants';
import PreSurveyState from './state';
import ACTION_STRINGS from './actionStrings';
import { PreSurveyActions } from './actionTypes';

const INITIAL_STATE: PreSurveyState = {
    sections: {},
    fetching: { ...DEFAULT_FETCHING_STATE },
    error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action: PreSurveyActions ) => {
    switch (action.type) {
      case ACTION_STRINGS.REPLACE_PRE_SURVEY: return { ...state, sections: action.payload, fetching: { ...DEFAULT_FETCHING_STATE } } as PreSurveyState
      case ACTION_STRINGS.SET_PRE_SURVEY: {
        const { key, value } = action.payload as { key: string, value: any }
        const questions = {...state.sections};
        questions[key] = value;
        return { ...state, sections: questions, fetching: { ...DEFAULT_FETCHING_STATE } } as PreSurveyState
      }
      case ACTION_STRINGS.ADD_PRE_SURVEY: {
        const entries = action.payload as {[key: string]: any}
        const questions = {...state.sections, ...entries};
        return { ...state, sections: questions, fetching: { ...DEFAULT_FETCHING_STATE } } as PreSurveyState
      }

      case ACTION_STRINGS.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

      default: return state;
  }
};

export default reducer;