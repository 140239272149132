import useI18n from 'i18n/useI18n';
import React from 'react';
import { H1 } from '@etiquette-ui/typography';
import CenterContainer from 'containers/CenterContainer';

const NotFound = () => {
    const { translate } = useI18n();
    return (
        <CenterContainer>
            <H1 style={{color: 'white'}}>{translate('Survey Not Found')}</H1>
        </CenterContainer>
    );
};

export default NotFound;
