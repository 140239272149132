import RenderNested from "core/classes/RenderNested";
import StaffRatingComponent from "../components/StaffRating";
import { StaffRatingCondition } from "../questions/StaffRating";

export default class StaffRating extends RenderNested<StaffRatingCondition> {

  getComponent() {
    return StaffRatingComponent;
  }
  
}
