import RenderNested from "core/classes/RenderNested";
import LongInputComponent from "../components/LongInput";

export default class LongInput extends RenderNested<string> {

  getComponent() {
    return LongInputComponent;
  }
  
}
