import AnswerType from "../types/AnswerType";
import Ticket from "../types/Ticket";
import { Product } from "../types/TicketResponse";
import WaiterOption from "../types/WaiterOption";

export const createAnswer: (ticket?: Ticket | null, waiter?: WaiterOption | null, products?: Product[]) => AnswerType = (
    ticket = null,
    waiter = null,
    products = []
) => ({
    type: "toast-config",
    waiter,
    ticket,
    products,
});

export const createTicket: (
    ticketN?: number | null,
    table?: string,
    guests?: number,
    amount?: number
) => Ticket = (ticketN = null, table = "", guests = 0, amount = 0) => ({
    ticketN,
    table,
    guests,
    amount,
});

export const createWaiterOption: (label: string, value: string) => WaiterOption = (
    label = "",
    value = ""
) => ({
    label,
    value,
});
