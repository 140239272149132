import PreSurveyComponent, { PreSurveyStatus } from 'core/presurvey/interfaces/PreSurveyComponent';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import Translation from 'core/render/interfaces/Translation';
import { Input } from '@etiquette-ui/inputs-dropdowns';
import useSettings from 'state/settings';
import usePreSurvey from 'state/presurvey';
import PreSurveyType from '../interfaces/PreSurveyType';
import SimpleInputAnswer from '../answers/SimpleInput';

const Container = styled.div`
    width: 100%;
    max-width: 500px;

    @media (min-width: 768px) {
        width: 70%;
    }
`;

type SettingsType = {
    name: Translation;
    getParam: string;
};

const createAnswer: (value: string)=>SimpleInputAnswer = (value) => ({
    type: 'simple-input', value,
});

const SimpleInput: React.FC<PreSurveyComponent<SettingsType>> = ({
    id: _id,
    settings,
}) => {
    const { translateObject } = useI18n();
    const { dispatcher: settingsDispatcher } = useSettings();
    const { state: { sections }, dispatcher: preSurveyDispatcher } = usePreSurvey();

    useEffect(() => {
        preSurveyDispatcher.setPreSurvey(_id, createAnswer(""));
    }, [preSurveyDispatcher, _id]);

    const section = useMemo(() => sections[_id] as PreSurveyType, [sections, _id]);

    useEffect(() => {
        if (!!section && section.type === 'simple-input' && section.value !== '') {
            settingsDispatcher.setConfigSectionsStatus(_id, PreSurveyStatus.VALID)
            return;
        }
        settingsDispatcher.setConfigSectionsStatus(_id, PreSurveyStatus.ERROR)
    }, [section, settingsDispatcher, _id]);

    return (
        <Container>
            <Input
                labelProps={{
                    label: translateObject(settings.name),
                }}
                inputProps={{
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => preSurveyDispatcher.setPreSurvey(_id, createAnswer(e.target.value)),
                    value: section.type === 'simple-input' ? section.value : '',
                }}
                iconProps={{
                    icon: '',
                }}
            />
        </Container>
    );
};

export default SimpleInput;
