import React from 'react';
import styled from 'styled-components';
import { BG } from '@etiquette-ui/colors';
import { Subtitle, P } from '@etiquette-ui/typography';
import useI18n from 'i18n/useI18n';
import useSettings from 'state/settings';

export const Container = styled.div`
    width: 100%;
    height: 7%;

    @media( min-width: 768px ){
        height: 7%;
        max-height: 62px;
    }
`;

const Body = styled.div<{ backgroundColor?: string }>`
    width: 100%;
    height: 100%;
    max-width: 1124px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : BG)};
    border-radius: 24px 24px 0px 0px;
    padding: 15px 30px;
    padding-bottom: 10px;
    flex-direction: column;
    gap: 0px;
`;

const Bar = styled.div`
    height: 8px;
    width: 100%;
    border-radius: 10px;
    margin: 10px auto 3px auto;
    background-color: #e0e0de;
`;

const Filler = styled.div<{ progress: number; color?: string }>`
    height: 100%;
    width: ${({ progress }) => `${progress}%`};
    background-color: ${({ color }) => (color ? color : '#388CFF')};
    border-radius: inherit;
    text-align: right;
    transition-property: width;
    transition-duration: 0.5s;
    span {
        color: white;
        font-weight: bold;
        font-size: 18px;
    }
`;

const CustomSubtitle = styled(Subtitle)`
    font-size: 18px;
    font-weight: bold;
    @media( min-width: 768px ){
        top: 18px;
    }
`;

const CustomText = styled(P)`
    font-size: 18px;
    font-weight: 500;
    @media( min-width: 768px ){
        top: 18px;
    }
`;

const getProgressText: (progress: number) => string = (progress) => {
  if(progress === 100) return 'Completed.. the team appreciate you!!!!';
  if(progress >= 90) return 'So close!!!';
  if(progress >= 80) return 'Light at end of Tunnel';
  if(progress >= 70) return 'Going great...';
  if(progress >= 60) return 'Half way there...';
  if(progress >= 50) return 'Keep going';
  if(progress >= 40) return 'Steady advance';
  if(progress >= 30) return "You're helping us";
  if(progress > 0) return 'Nice Start';
  return "Let's get started";
}

const ProgressBar: React.FC<{ surveyBackground?: string }> = ({ surveyBackground }) => {
    const { state: { progress } } = useSettings();
    const { translate } = useI18n();

    return (
        <Container>
            <Body backgroundColor={surveyBackground}>
                <div style={{display: 'flex', gap: '20px'}}>
                <CustomSubtitle>{`${progress}%`}</CustomSubtitle>
                <CustomText>{translate(getProgressText(progress))}</CustomText>
                </div>
                <Bar>
                    <Filler progress={progress}>
                        <span></span>
                    </Filler>
                </Bar>
            </Body>
        </Container>
    );
};

export default ProgressBar;
