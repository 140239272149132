import { useUUID } from 'providers/uuid';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const Window = styled.iframe`
    width: 100vw;
    height: 100vh;
    border: none;
`;

const Login = () => {
    const uuid = useUUID();

    useEffect(() => {
      const loginFrame = document.getElementById('loginframe') as HTMLIFrameElement;
      loginFrame.addEventListener('load', onLoad);
    }, []);

    const onLoad = () => {
        const loginFrame = document.getElementById('loginframe') as HTMLIFrameElement;
        if(!!loginFrame && loginFrame.contentWindow){
            loginFrame.contentWindow.postMessage('getData', process.env.REACT_APP_AUTH_URL ?? '');

            window.addEventListener('message', e => {
                console.log('message', e);
                if (typeof e.data !== 'string' || !e.data.startsWith('data:')) return;
                const toStore = e.data.slice(5);
                if (toStore !== "") {

                    const parts = toStore.split('|');
                    parts.pop();
                    const data = parts.join('|');

                    localStorage.setItem('auth', data)

                    window.location.href = `/${uuid}`;
                }
            }, false);
        }
    }

    return (
        <Window id="loginframe" title="login" src={process.env.REACT_APP_AUTH_URL}></Window >
    );
}


export default Login;