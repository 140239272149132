import PreSurveyComponent from "core/presurvey/interfaces/PreSurveyComponent";
import React, { FC, useEffect, useMemo } from "react";
import { Container } from "./styles";
import usePreSurvey from "state/presurvey";
import TicketInfo from "./TicketInfo";
import { DataProvider } from "./data";
import SettingsType from "./types/SettingsType";
import { pathFinder } from "utils/objectUtils";
import useGlobalVariables from "state/globalVariables";

const Default: FC<PreSurveyComponent<SettingsType>> = ({ id, settings }) => {
    const {
        state: { sections },
    } = usePreSurvey();
    const { dispatcher: globalVariablesDispatcher } = useGlobalVariables();

    const section = useMemo(() => {
        const section = sections[id];
        if (section?.type === "toast-config") return section;
        return null;
    }, [sections, id]);

    useEffect(() => {
        if (!!settings.globalVariables && !!section) {            
            for (const key in settings.globalVariables) {
                globalVariablesDispatcher.setGlobalVariables(key, pathFinder(section, settings.globalVariables[key]));
            }
        }
    }, [section, globalVariablesDispatcher, settings.globalVariables]);

    return (
        <DataProvider
            employeesUrl={settings.employeesUrl}
            serverUserTypes={settings.serverUserTypes}
            ticketsUrl={settings.ticketUrl}
            section={section}
            id={id}
        >
            <Container>
                <TicketInfo id={id} section={section} />
            </Container>
        </DataProvider>
    );
};

export default Default;
