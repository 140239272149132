import RenderNested from "core/classes/RenderNested";
import RatingComponent from "../components/Rating";
import { RatingCondition } from "../questions/Rating";

export default class Rating extends RenderNested<RatingCondition> {

  getComponent() {
    return RatingComponent;
  }
  
}
