import { FC } from "react";
import Renderer from "core/classes/Renderer";

export default class PluginRenderer extends Renderer {
  protected plugin: any;

  setComponent(plugin: FC) {
    this.plugin = plugin;
  }

  getComponent() {
    return this.plugin;
  }
}
