import { useCallback } from "react";
import Ticket from "../types/Ticket";
import AnswerType from "../types/AnswerType";
import usePreSurvey from "state/presurvey";
import { createAnswer, createTicket } from "./utils";
import WaiterOption from "../types/WaiterOption";

const useUpdateAnswer = (id: string, section: AnswerType | null) => {
    const { dispatcher: preSurveyDispatcher } = usePreSurvey();

    const updateTicket = useCallback(
      (key: keyof Ticket, value: number | null) => {
            const newSection: AnswerType = section && value !== null ? { ...section } : createAnswer();

            const newTicket = section?.ticket && value !== null ? { ...section.ticket } : createTicket();
            newTicket[key] = value;

            newSection.ticket = newTicket;
            preSurveyDispatcher.setPreSurvey(id, newSection);
      },
      [id, section, preSurveyDispatcher]
    );

    const updateWaiter = useCallback(
      (value: WaiterOption) => {
            const newSection: AnswerType = section ? { ...section } : createAnswer();

            const newWaiter = value;

            newSection.waiter = newWaiter;
            preSurveyDispatcher.setPreSurvey(id, newSection);
      },
      [id, section, preSurveyDispatcher]
    );

    return { updateTicket, updateWaiter }
  };

  export default useUpdateAnswer;