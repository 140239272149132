import { ResetError } from 'state/interfaces/ResetError';
import ACTION_STRINGS from './actionStrings';
import { SetGlobalVariables, ReplaceGlobalVariables, AddGlobalVariables } from './actionTypes';

export const setGlobalVariablesAction = (key: string, value: any): SetGlobalVariables => ({
    type: ACTION_STRINGS.SET_GLOBAL_VARIABLES,
    payload: { key, value },
});

export const replaceGlobalVariablesAction = (payload: {[key: string]: any}): ReplaceGlobalVariables => ({
    type: ACTION_STRINGS.REPLACE_GLOBAL_VARIABLES,
    payload,
});

export const addGlobalVariablesAction = (payload: {[key: string]: any}): AddGlobalVariables => ({
    type: ACTION_STRINGS.ADD_GLOBAL_VARIABLES,
    payload,
});

export const resetError = (): ResetError<ACTION_STRINGS> => ({
    type: ACTION_STRINGS.RESET_ERROR,
    payload: {}
});