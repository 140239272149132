import { PreSurveyStatus } from 'core/presurvey/interfaces/PreSurveyComponent';
import { ResetError } from 'state/interfaces/ResetError';
import ACTION_STRINGS from './actionStrings';
import { SetNeedsConfigPage, SetConfigCompleted, SetProgress, SetConfigSectionsStatus, SetSurveyCompleted, SetSelectedLang, SetReference } from './actionTypes';

export const setNeedsConfigPageAction = (payload: boolean): SetNeedsConfigPage => ({
    type: ACTION_STRINGS.SET_NEEDS_CONFIG_PAGE,
    payload,
});

export const setConfigCompletedAction = (payload: boolean): SetConfigCompleted => ({
    type: ACTION_STRINGS.SET_CONFIG_COMPLETED,
    payload,
});

export const setProgressAction = (payload: number): SetProgress => ({
    type: ACTION_STRINGS.SET_PROGRESS,
    payload,
});

export const setConfigSectionsStatusAction = (index: string, status: PreSurveyStatus): SetConfigSectionsStatus => ({
    type: ACTION_STRINGS.SET_CONFIG_SECTIONS_STATUS,
    payload: { index, status },
});

export const setSurveyCompletedAction = (payload: boolean): SetSurveyCompleted => ({
    type: ACTION_STRINGS.SET_SURVEY_COMPLETED,
    payload,
});

export const setSelectedLang = (payload: string): SetSelectedLang => ({
    type: ACTION_STRINGS.SET_SELECTED_LANG,
    payload,
});

export const setReferenceAction = (payload: string | null): SetReference => ({
    type: ACTION_STRINGS.SET_REFERENCE,
    payload,
});

export const resetError = (): ResetError<ACTION_STRINGS> => ({
    type: ACTION_STRINGS.RESET_ERROR,
    payload: {}
});