enum ACTION_STRINGS {
    SET_NEEDS_CONFIG_PAGE = "[SETTINGS] SET NEEDS CONFIG PAGE",
    SET_CONFIG_COMPLETED = "[SETTINGS] SET CONFIG COMPLETED",
    SET_SURVEY_COMPLETED = "[SETTINGS] SET SURVEY COMPLETED",
    SET_PROGRESS = "[SETTINGS] SET PROGRESS",
    SET_CONFIG_SECTIONS_STATUS = "[SETTINGS] SET CONFIG SECTIONS STATUS",
    SET_SELECTED_LANG = "[SETTINGS] SET SELECTED LANG",
    SET_REFERENCE = "[SETTINGS] SET REFERENCE",


    RESET_ERROR = "[SETTINGS] RESET ERROR",
}

export default ACTION_STRINGS;