export const isAnswerEmpty = (value: any, type: string): boolean => {
    switch (type) {
        case 'simple-input':
            return value === '';
        case 'long-input':
            return value === '';
        case 'date-input':
            return value === '';
        case 'rating':
            return value === 0 || value === null || value === undefined;
        case 'options-in':
            return value === '';
        case 'multiple-choice':
            return value.length === 0;
        case 'single-choice':
            return value === '';
        case 'topics': {
            let empty = true;
            Object.values(value).forEach((val) => {
                if (val !== '') empty = false;
            });
            return empty;
        }
        case 'form': {
            let empty = true;
            for (const val of Object.values(value)) {
                if(val === null) continue;
                if(typeof val === 'string' && val !== ''){ 
                    empty = false;
                    continue;
                }
                if(typeof val === 'object' && Array.isArray(val) && val.length !== 0 ){ 
                    empty = false;
                    continue;
                }
                if(typeof val === 'object'){ 
                    if (val.hasOwnProperty('phone') && (val as any).phone !== '') empty = false;
                    continue;
                }
            }
            return empty;
        }
        case 'staff-rating': {
            const { rating, userId } = value;
            return rating === 0 || rating === null || rating === undefined || userId === null;
        }
        case 'staff-options': {
            const { userId } = value;
            return userId === null;
        }
        default:
            //console.error('isAnswerEmpty: type not found', type);
    }
    return false;
};

const validateAnswer: (value: any, type: string) => boolean = (value, type) => {
    return true;
};

const validateSurveyAnswers = (answers: { [key: string]: any }): { [key: string]: any } => {
    const validation = {} as { [key: string]: any };

    for (const key of Object.keys(answers)) {
        const { value, type } = answers[key];
        validation[key] = { value, isEmpty: isAnswerEmpty(value, type), isValid: validateAnswer(value, type) };
    }

    return validation;
};

export default validateSurveyAnswers;