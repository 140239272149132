import { Dispatch } from "react";
import { DataActions } from "./actionTypes";
import { setGlobalVariablesAction, replaceGlobalVariablesAction, resetError, addGlobalVariablesAction } from "./actions";

export default class DataDispatcher {
    _dispatch: Dispatch<DataActions>;

    constructor(dispatch: Dispatch<DataActions>) {
        this._dispatch = dispatch;
    }

    replaceGlobalVariables = (config: {[key: string]: any}) => this._dispatch(replaceGlobalVariablesAction(config));

    setGlobalVariables = (key: string, value: any) => this._dispatch(setGlobalVariablesAction(key, value));

    addGlobalVariables = (config: {[key: string]: any}) => this._dispatch(addGlobalVariablesAction(config));

    resetError = () => this._dispatch(resetError());
}