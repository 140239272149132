import { legacy_createStore as createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootSaga from './rootSaga';
import rootReducer from "./rootReducer";
import { routerMiddleware } from 'connected-react-router';
import { history } from "../resources/history";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(history), sagaMiddleware];

const reducer = rootReducer(history);

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export type AppState = ReturnType<typeof reducer>

export default store;