import React, { useCallback, useMemo } from "react";
import {
    ProductContainer,
    ProductDescription,
    ProductImageContainer,
    ProductTitle,
} from "./styles";
import { Stars } from "@etiquette-ui/misc";
import XetuxProduct from "./types/XetuxProduct";
import useI18n from "i18n/useI18n";
import ProductRating from "./types/ProductRating";
import { TextArea } from "@etiquette-ui/inputs-dropdowns";


const Product: React.FC<{
    id: string;
    product: XetuxProduct;
    productRating: ProductRating;
    rateProduct: (data: ProductRating) => void;
    commentLabel: string;
    imgURL: string;
}> = ({ id, product, productRating, rateProduct, commentLabel, imgURL }) => {
    const { translateObject } = useI18n();

    const handleStars = useCallback((rating: number) => {
        rateProduct({
            ...productRating,
            rating: rating,
        });
    }, [rateProduct, productRating]);

    const handleComment = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        rateProduct({
            ...productRating,
            comment: e.target.value,
        });
    }, [rateProduct, productRating]);

    const rateTranslation = useMemo(
        () =>
            translateObject({
                en: `Your rated ${productRating.rating} stars`,
                es: `Calificado con ${productRating.rating} estrellas`,
            }),
        [productRating.rating, translateObject]
    );

    return (
        <ProductContainer id={id}>
            <ProductTitle>{translateObject(product.translations.title)}</ProductTitle>
            <Stars value={productRating.rating} setValue={handleStars} />
            <ProductDescription>{rateTranslation}</ProductDescription>
            <ProductImageContainer>
                <img src={product.img ? `${imgURL}${product.img}` : `${imgURL}noimage.svg`} alt={product.translations.title.en} />
            </ProductImageContainer>
            <TextArea
                inputProps={{
                    onChange: handleComment,
                    value: productRating.comment,
                }}
                iconProps={{
                    icon: "comment",
                }}
                labelProps={{
                    label: commentLabel,
                }}
            />
        </ProductContainer>
    );
};

export default Product;
