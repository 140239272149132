import styled from 'styled-components';

const SurveyContainer = styled.form`

    height: 90%;
    min-height: 79vh;
    max-width: 1124px;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 15px;

    display: flex;
    flex: 1;
    min-height: 0;
    position: relative;

    &.__withProgressBar {
        height: 82%;
    }

    @media( min-width: 768px ){
        height: 90%;

        &.__withProgressBar {
            height: 83%;
        }
    }
`;


export default SurveyContainer;
