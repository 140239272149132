import Translation from "core/render/interfaces/Translation";

export const MONTHS: Translation[] = [
  {
    'en': 'January',
    'es': 'Enero'
  },
  {
    'en': 'February',
    'es': 'Febrero'
  },
  {
    'en': 'March',
    'es': 'Marzo'
  },
  {
    'en': 'April',
    'es': 'Abril'
  },
  {
    'en': 'May',
    'es': 'Mayo'
  },
  {
    'en': 'June',
    'es': 'Junio'
  },
  {
    'en': 'July',
    'es': 'Julio'
  },
  {
    'en': 'August',
    'es': 'Agosto'
  },
  {
    'en': 'September',
    'es': 'Septiembre'
  },
  {
    'en': 'October',
    'es': 'Octubre'
  },
  {
    'en': 'November',
    'es': 'Noviembre'
  },
  {
    'en': 'December',
    'es': 'Diciembre'
  }
];