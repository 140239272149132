import Renderer from "core/classes/Renderer";
import FormComponent from "../components/Form";

export default class Form extends Renderer {

  getComponent() {
    return FormComponent;
  }
  
}
