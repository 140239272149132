import { DEFAULT_FETCHING_STATE, DEFAULT_NO_ERROR } from 'state/constants';
import ResponseState from './state';
import ACTION_STRINGS from './actionStrings';
import { ResponseActions } from './actionTypes';
import { flatTree } from 'utils/objectUtils';
import validateSurveyAnswers from 'core/utils/validation';

const INITIAL_STATE: ResponseState = {
    answers: {},
    answersHash: {},
    validationTree: {},
    fetching: { ...DEFAULT_FETCHING_STATE },
    error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action: ResponseActions) => {
    switch (action.type) {
        case ACTION_STRINGS.REPLACE_RESPONSE: {
            const hash = flatTree(action.payload);
            const newHash = {} as { [key: string]: any };
            for (const key in hash) {
                const { value, type } = hash[key];
                newHash[key] = { value, type };
            }
            return { ...state, answers: action.payload, answersHash: hash, fetching: { ...DEFAULT_FETCHING_STATE }, } as ResponseState;
        }
        case ACTION_STRINGS.CREATE_VALIDATION_TREE: {
            const validationTree = validateSurveyAnswers(action.payload);
            return { ...state, validationTree: validationTree, fetching: { ...DEFAULT_FETCHING_STATE }, } as ResponseState;
        }

        case ACTION_STRINGS.SEND_START: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } } as ResponseState
        case ACTION_STRINGS.SEND_SUCCESS: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE } } as ResponseState
        case ACTION_STRINGS.SEND_ERROR: return { ...state, fetching: {...DEFAULT_FETCHING_STATE}, error: {timestamp: Date.now(), message: action.payload} } as ResponseState

        case ACTION_STRINGS.DELIVER_START: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } } as ResponseState
        case ACTION_STRINGS.DELIVER_SUCCESS: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE } } as ResponseState
        case ACTION_STRINGS.DELIVER_ERROR: return { ...state, fetching: {...DEFAULT_FETCHING_STATE}, error: {timestamp: Date.now(), message: action.payload} } as ResponseState

        case ACTION_STRINGS.SKIP_START: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } } as ResponseState
        case ACTION_STRINGS.SKIP_SUCCESS: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE } } as ResponseState
        case ACTION_STRINGS.SKIP_ERROR: return { ...state, fetching: {...DEFAULT_FETCHING_STATE}, error: {timestamp: Date.now(), message: action.payload} } as ResponseState

        case ACTION_STRINGS.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

        default:
            return state;
    }
};

export default reducer;
