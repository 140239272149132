const ES: { [key: string]: string } = {
    'Survey Not Found': 'Encuesta no encontrada',
    'Loading...': 'Cargando...',
    'End Survey Anytime': 'Finaliza la encuesta en cualquier momento',
    'Start Survey': 'Empezar Encuesta',

    'Check data below and start survey when ready': 'Verifique los datos y comience la encuesta cuando esté listo.',

    "Let's get started": 'Empecemos',
    'Nice Start': 'Buen comienzo',
    "You're helping us": 'Nos esta ayudando',
    'Steady advance': 'Avance constante',
    'Keep going': 'Sigue adelante',
    'Half way there...': 'A medio camino',
    'Going great...': 'Va muy bien',
    'Light at end of Tunnel': 'Ya se ve la luz al final del camino',
    'So close!!!': 'Muy cerca',
    'Completed.. the team appreciate you!!!!': '¡Terminado!... el equipo le da las gracias',

    'Are you finished with the survey?': '¿Terminaste con la encuesta?',
    'Yes, I am done': 'Si, he terminado',
    'No, go back': 'No, volver',
    'You completed': 'Has completado  %progress%%',

    "Don't Remember": "No recuerdo",
    "All Team Members": "Todo el equipo",

    "Thank you for your time!": "¡Gracias por su tiempo!",
    "We hope you have enjoyed your visit.": "Esperamos que haya disfrutado de su visita.",

    "Currently Not Rated": "Actualmente no calificado",
    "Rate your experience": "Califica tu experiencia",
    "Rate your experience with": "Califica tu experiencia con %name%",

    "Select an option": "Selecciona una opción",
    "All Countries": "Todos los países",
    "Invalid Phone Number": "Número de teléfono invalido",
};

export default ES;
