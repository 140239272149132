import React, { useMemo } from "react";
import ToastProduct from "./types/ToastProduct";
import ProductRating from "./types/ProductRating";
import useI18n from "i18n/useI18n";
import { ProductList, Rated } from "./styles";
import { useScroll } from "providers/scroll";
import { cleanId } from "core/utils";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import Product from "./Product";
import IToastProducts from "./types/IToastProducts";
import { DISABLED_BUTTON } from "@etiquette-ui/colors";

const Products: React.FC<{
    question: IToastProducts;
    response: ProductRating[];
    setResponse: (value: any) => void;
    list: ToastProduct[];
    questionId: string;
}> = ({ question, response, setResponse, list, questionId }) => {
    const { translateObject } = useI18n();

    const { next } = useScroll();

    const totalRated = useMemo(
        () =>
            response.reduce((acc: number, curr: ProductRating) => {
                if (curr.rating > 0) {
                    return acc + 1;
                }
                return acc;
            }, 0),
        [response]
    );

    const rateTranslation = useMemo(
        () =>
            translateObject({
                en: `${totalRated} of ${list.length} items rated.`,
                es: `${totalRated} de ${list.length} calificados.`,
            }),
        [totalRated, list.length, translateObject]
    );

    const rateProduct = (i: number, id: number) => (data: ProductRating) => {
        const newResponse = [...response];
        newResponse[i] = data;
        setResponse(newResponse);

        const product = list.find((el) => el.id === id);
        if (product === undefined) return;
        const index = list.indexOf(product);
        if (index === -1) return;
        if (index === list.length - 1 && response[i].rating !== data.rating && data.rating > 0) {
            next(cleanId(questionId));
            return;
        }
        const element = document.getElementById(`${list[index + 1].id}`);
        if (!!element && response[i].rating !== data.rating && data.rating > 0)
            smoothScrollIntoView(element, {
                scrollMode: "always",
                behavior: "smooth",
                inline: "center",
                block: "nearest",
            });
    };

    return (
        <>
            <Rated>{rateTranslation}</Rated>
            <ProductList>
                {list.map((p, i) => (
                    <React.Fragment key={`${i}-${p.id}`}>
                        <Product
                            
                            id={`${p.id}`}
                            product={p}
                            productRating={response[i]}
                            rateProduct={rateProduct(i, p.id)}
                            commentLabel={
                                !!question.commentBoxTitle
                                    ? translateObject(question.commentBoxTitle)
                                    : ""
                            }
                            imgURL={question.imgURL}
                        />

                        {i < list.length - 1 && (
                            <div
                                style={{
                                    flex: 1,
                                    width: "3px",
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 5px",
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: DISABLED_BUTTON,
                                        height: "40%",
                                        width: "2px",
                                        borderRadius: "5px",
                                        zIndex: -1000,
                                    }}
                                />
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </ProductList>
        </>
    );
};

export default Products;
