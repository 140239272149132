const EN: { [key: string]: string } = {
    'Survey Not Found': 'Survey Not Found',
    'Loading...': 'Loading...',
    'End Survey Anytime': 'End Survey Anytime',
    'Start Survey': 'Start Survey',

    'Check data below and start survey when ready': 'Check data below and start survey when ready.',

    "Let's get started": "Let's get started",
    'Nice Start': 'Nice Start',
    "You're helping us": "You're helping us",
    'Steady advance': 'Steady advance',
    'Keep going': 'Keep going',
    'Half way there...': 'Half way there...',
    'Going great...': 'Going great...',
    'Light at end of Tunnel': 'Light at end of Tunnel',
    'So close!!!': 'So close!!!',
    'Completed.. the team appreciate you!!!!': 'Completed.. the team appreciate you!!!!',

    'Are you finished with the survey?': 'Are you finished with the survey?',
    'Yes, I am done': 'Yes, I am done',
    'No, go back': 'No, go back',
    'You completed': 'You completed %progress%%',

    "Don't Remember": "Don't Remember",
    "All Team Members": "All Team Members",

    "Thank you for your time!": "Thank you for your time!",
    "We hope you have enjoyed your visit.": "We hope you have enjoyed your visit.",

    "Currently Not Rated": "Currently not rated",
    "Rate your experience": "Rate your experience",
    "Rate your experience with": "Rate your experience with %name%",

    "Select an option": "Select an option",
    "All Countries": "All Countries",
    "Invalid Phone Number": "Invalid Phone Number",
};

export default EN;
